import React from "react"
import MuiPaper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        width: '100%',
        marginTop: '20px',
        marginBottom: '10px',
    },
})

function Paper({ children, ...props }) {
    const classes = useStyles()

    return (
        <MuiPaper className={classes.paper} elevation={8} {...props}>
            {children}
        </MuiPaper>
    )
}

export default Paper
