import React from "react"
import {
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    Grid,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles({
    title: {
        textTransform: 'uppercase',
    },
})

function Role({ name, description, switchRole }) {
    const classes = useStyles()

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="h2" className={classes.title}>
                        {name}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => switchRole(name)}
                    >
                        I'm a {name}
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default Role
