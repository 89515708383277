import React from "react"
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    alertContainer: {
        borderWidth: '1px',
        borderColor: theme.palette.success.dark,
        borderStyle: 'solid',
    },
}))

function SuccessAlert({ title, children }) {
    const classes = useStyles()

    return (
        <Alert severity="success" className={classes.alertContainer}>
            <AlertTitle>{title}</AlertTitle>
            {children}
        </Alert>
    )
}

export default SuccessAlert
