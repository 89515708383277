import { postAuth } from '../services/http'
import { updateAfterResetKyb } from './company'

export const RESET_KYB_REQUEST = 'RESET_KYB_REQUEST'
export const RESET_KYB_SUCCESS = 'RESET_KYB_SUCCESS'
export const RESET_KYB_FAILURE = 'RESET_KYB_FAILURE'

export const requestResetKyb = () => ({
    type: RESET_KYB_REQUEST,
})

export const successResetKyb = () => ({
    type: RESET_KYB_SUCCESS,
})

export const failureResetKyb = (errorMessage) => ({
    type: RESET_KYB_FAILURE,
    errorMessage: errorMessage,
})

export function fetchResetKyb() {
    return async dispatch => {
        dispatch(requestResetKyb())

        try {
            const response = await postAuth('/company/profile/resetkyb', {})
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(updateAfterResetKyb(body.message, false))
                dispatch(successResetKyb())
            } else {
                dispatch(failureResetKyb(body.message))
            }
        } catch (e) {
            dispatch(failureResetKyb('Something went wrong'))
        }
    }
}
