import { postAuth } from '../services/http'
import { updateAfterResetKyc } from './profile'

export const RESET_KYC_REQUEST = 'RESET_KYC_REQUEST'
export const RESET_KYC_SUCCESS = 'RESET_KYC_SUCCESS'
export const RESET_KYC_FAILURE = 'RESET_KYC_FAILURE'

export const requestResetKyc = () => ({
    type: RESET_KYC_REQUEST,
})

export const successResetKyc = () => ({
    type: RESET_KYC_SUCCESS,
})

export const failureResetKyc = (errorMessage) => ({
    type: RESET_KYC_FAILURE,
    errorMessage: errorMessage,
})

export function fetchResetKyc() {
    return async dispatch => {
        dispatch(requestResetKyc())

        try {
            const response = await postAuth('/user/profile/resetkyc', {})
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(updateAfterResetKyc(body.message, false))
                dispatch(successResetKyc())
            } else {
                dispatch(failureResetKyc(body.message))
            }
        } catch (e) {
            dispatch(failureResetKyc('Something went wrong'))
        }
    }
}
