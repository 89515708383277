import * as actions from '../actions/profile'

export const initialState = {
    uuid: '',
    role: 'broker',
    emailAddress: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    title: '',
    dateOfBirth: 473385600,
    gender: 'M',
    nationality: '',
    registrationDate: 1593475200,
    kycStatus: 'open',
    hasEntity: true,
    streetName: '',
    houseNumber: '',
    additionalAddressLine: '',
    zipCode: '',
    city: '',
    country: '',
    isReadyToSubmitKyc: false,
    loading: true,
    hasErrors: false,
    errorMessage: '',
}

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case actions.PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                uuid: '',
                role: 'broker',
                emailAddress: '',
                firstName: '',
                middleName: '',
                lastName: '',
                phoneNumber: '',
                title: '',
                dateOfBirth: 473385600,
                gender: 'M',
                nationality: '',
                registrationDate: 1593475200,
                kycStatus: 'open',
                hasEntity: true,
                streetName: '',
                houseNumber: '',
                additionalAddressLine: '',
                zipCode: '',
                city: '',
                country: '',
                isReadyToSubmitKyc: false,
                errorMessage: action.errorMessage,
            }
        case actions.PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                uuid: action.uuid,
                role: action.role,
                emailAddress: action.emailAddress,
                firstName: action.firstName,
                middleName: action.middleName,
                lastName: action.lastName,
                phoneNumber: action.phoneNumber,
                title: action.title,
                dateOfBirth: action.dateOfBirth,
                gender: action.gender,
                nationality: action.nationality,
                registrationDate: action.registrationDate,
                kycStatus: action.kycStatus,
                hasEntity: action.hasEntity,
                streetName: action.streetName,
                houseNumber: action.houseNumber,
                additionalAddressLine: action.additionalAddressLine,
                zipCode: action.zipCode,
                city: action.city,
                country: action.country,
                isReadyToSubmitKyc: action.isReadyToSubmitKyc,
                errorMessage: '',
            }
        case actions.UPDATE_AFTER_SAVE_PROFILE:
            return {
                ...state,
                role: action.role,
                firstName: action.firstName,
                middleName: action.middleName,
                lastName: action.lastName,
                phoneNumber: action.phoneNumber,
                title: action.title,
                dateOfBirth: action.dateOfBirth,
                gender: action.gender,
                nationality: action.nationality,
                hasEntity: action.hasEntity,
                streetName: action.streetName,
                houseNumber: action.houseNumber,
                additionalAddressLine: action.additionalAddressLine,
                zipCode: action.zipCode,
                city: action.city,
                country: action.country,
                isReadyToSubmitKyc: action.isReadyToSubmitKyc,
            }
        case actions.UPDATE_AFTER_SUBMIT_KYC:
            return {
                ...state,
                kycStatus: action.kycStatus,
                isReadyToSubmitKyc: action.isReadyToSubmitKyc,
            }
        case actions.UPDATE_AFTER_RESET_KYC:
            return {
                ...state,
                kycStatus: action.kycStatus,
            }
        default:
            return state
    }
}