import React from "react"
import CheckBox from "../Common/Forms/CheckBox"
import ErrorAlert from "../Common/Alerts/ErrorAlert"
import TextField from "../Common/Forms/Inputs/TextField"
import MultiLine from "../Common/Forms/Inputs/MultiLine"
import DropDown from "../Common/Forms/DropDown"
import Slider from "../Common/Forms/Slider"
import RadioGroup from '../Common/Forms/RadioGroup'
import SubmitButton from '../Common/Forms/Buttons/SubmitButton'
import PaperTitle from '../Common/Typography/PaperTitle'
import PaperIntro from '../Common/Typography/PaperIntro'
import PageTitle from "../Common/Typography/PageTitle"
import TextButton from "../Common/TextButton"
import Paper from "../Common/Paper"
import ProductListingPricingType from "./ProductListingPricingType"
import PaperSeparator from "../Common/PaperSeparator"
import { FaSave } from "react-icons/fa"
import { MdAddCircle } from "react-icons/md"
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Formik, Form, FieldArray } from "formik"

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    datePicker: {
        width: '100%',
    },
}))

function CreateProductListingForm({
    createProductListing,
    createProductListingState,
    profile,
    categories,
    productTypes,
}) {
    const classes = useStyles()
    const categoryOptions = []
    const productTypeOptions = []
    categories.items.map((category, key) => (
        categoryOptions.push({
            key: key,
            label: category.name,
            value: category.uuid,
        })
    ))
    productTypes.items.map((productType, key) => (
        productTypeOptions.push({
            key: key,
            label: productType.name,
            value: productType.uuid,
            categoryUuid: productType.categoryUuid,
        })
    ))
    const filteredProductTypes = filter => {
        return productTypeOptions.filter(
            productType => productType.categoryUuid === filter
        )
    }

    return (
        <React.Fragment>
            <PageTitle>Add a new product to be listed</PageTitle>

            <Formik
                initialValues={{
                    category: '',
                    productType: '',
                    title: '',
                    brand: '',
                    model: '',
                    moq: '',
                    description: '',
                    packagingInfo: '',
                    paymentInfo: '',
                    targetMarketInfo: '',
                    isShippingOffered: false,
                    shippingInfo: '',
                    actorRole: '',
                    listingType: 'offer',
                    degreesOfSeparation: 2,
                    pricings: [],
                }}
                validate={values => {
                    let errors = {}

                    if (!values.category || values.category === '') errors.category = 'You have to select a category'
                    if (!values.productType || values.productType === '') {
                        errors.productType = 'You have to select a subcategory/classification'
                    }

                    return errors
                }}
                onSubmit={event => createProductListing(event)}
            >
                {({ handleBlur, handleChange, values, errors }) => (
                    <Form>
                        {
                            createProductListingState.hasErrors &&
                            <ErrorAlert title="Something went wrong while trying to save this product listing...">
                                {createProductListingState.errorMessage}
                            </ErrorAlert>
                        }

                        <div className={classes.root}>
                            <Paper>
                                <PaperTitle>Product specifications</PaperTitle>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <DropDown
                                            label="Category"
                                            id="category"
                                            onChange={(e, v) => handleChange(e, v)}
                                            onBlur={handleBlur}
                                            required
                                            error={errors.category}
                                            options={categoryOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DropDown
                                            label="Subcategory/classification"
                                            id="productType"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            error={errors.productType}
                                            options={filteredProductTypes(values.category)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField inputName="title" label="Listing title" value={values.title} required />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField inputName="brand" label="Brand" value={values.brand} required />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField inputName="model" label="Model" value={values.model} required />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField inputName="moq" label="Minimum order quantity" value={values.moq} required />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper>
                                <PaperTitle>Descriptions</PaperTitle>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <MultiLine
                                            inputName="description"
                                            label="Product description"
                                            value={values.description}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MultiLine
                                            inputName="packagingInfo"
                                            label="Packaging information"
                                            value={values.packagingInfo}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MultiLine
                                            inputName="paymentInfo"
                                            label="Payment information"
                                            value={values.paymentInfo}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MultiLine
                                            inputName="targetMarketInfo"
                                            label="Targeted markets"
                                            value={values.targetMarketInfo}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MultiLine
                                            inputName="shippingInfo"
                                            label="Shipping information"
                                            value={values.shippingInfo}
                                            required
                                            disabled={!values.isShippingOffered}
                                        />
                                        <CheckBox
                                            inputName="isShippingOffered"
                                            label="Shipping possible (please provide shipping information above)"
                                            selectedValue={values.isShippingOffered}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper>
                                <PaperTitle>Pricing</PaperTitle>

                                <FieldArray
                                    name="pricings"
                                    render={arrayHelpers => (
                                        <Grid container spacing={2}>
                                            {values.pricings.map((pricingType, index) => (
                                                <ProductListingPricingType
                                                    index={index}
                                                    key={index}
                                                    pricingType={pricingType}
                                                    handleDelete={() => arrayHelpers.remove(index)}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            ))}
                                            
                                            <Grid item xs={12}>
                                                <TextButton
                                                    startIcon={<MdAddCircle />}
                                                    onClick={() => arrayHelpers.insert(values.pricings.length, {
                                                        description: '',
                                                        pricingType: '',
                                                        price: '',
                                                    })}
                                                >
                                                    Add pricing option
                                                </TextButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                            </Paper>

                            <Paper>
                                <PaperTitle>About your (company's) role</PaperTitle>
                                <PaperIntro>
                                    Please note, depending on your personal and/or company's profile, some fields might be disabled
                                </PaperIntro>

                                <Grid container spacing={2}>
                                    {
                                        profile.role === 'broker' &&
                                        <Grid item xs={12} md={6}>
                                            <Slider
                                                label="Degrees of separation between you and the buyer or seller:"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={false}
                                                min={1}
                                                max={4}
                                                value={values.degreesOfSeparation}
                                                name='degreesOfSeparation'
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <DropDown
                                            disabled={profile.role === 'broker'}
                                            label="Your role in the context of the listing"
                                            id="actorRole"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                            error={errors.actRole}
                                            selectedValue={profile.role === 'broker' ? 'BROKER' : ''}
                                            options={[
                                                { label: 'Manufacturer', value: 'MANUFACTURER' },
                                                { label: 'Authorized distributor', value: 'AUTHORIZED_DISTRIBUTOR' },
                                                { label: 'Distributor', value: 'DISTRIBUTOR' },
                                                { label: 'Trading company', value: 'TRADING_COMPANY' },
                                                { label: 'Other private company', value: 'OTHER_PRIVATE_COMPANY' },
                                                { label: 'Broker', value: 'BROKER' },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            disabled={profile.role !== 'broker'}
                                            label="Listing type:"
                                            selectedValue={
                                                profile.role === 'buyer' ? 'request' : 'offer'
                                            }
                                            inputName="listingType"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            options={[
                                                { label: 'Product request', value: 'request' },
                                                { label: 'Product offering', value: 'offer' },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>

                                <PaperSeparator />

                                <Grid container spacing={2} className={classes.buttons}>
                                    <Grid item>
                                        <SubmitButton
                                            disabled={createProductListingState.loading}
                                            startIcon={<FaSave />}
                                        >
                                            {createProductListingState.loading ? "Please wait..." : "Save"}
                                        </SubmitButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default CreateProductListingForm
