import React from 'react'
import { connect } from 'react-redux'
import { signOut } from '../../actions/signIn'
import { Redirect } from 'react-router-dom'

const SignOut = ({ dispatch }) => {
    dispatch(signOut())

    return (
        <Redirect to="/signin" />
    )
}

export default connect(null)(SignOut)
