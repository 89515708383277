import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'none',
            boxShadow: 'inset 0 -8px 0px rgba(27,108,168,0.3)',
        },
    },
}))

function Link({ to, children }) {
    const classes = useStyles()

    return (
        <MuiLink component={RouterLink} to={to} className={classes.link}>
            {children}
        </MuiLink>
    )
}

export default Link
