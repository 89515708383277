import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import PageTitle from "../Common/Typography/PageTitle"
import PaperTitle from "../Common/Typography/PaperTitle"
import PaperIntro from "../Common/Typography/PaperIntro"
import FileUpload from "../Common/Forms/Inputs/FileUpload"
import Paper from "../Common/Paper"
import { FaSave, FaCaretLeft } from "react-icons/fa"
import Button from '../Common/Forms/Buttons/Button'
import ErrorAlert from '../Common/Alerts/ErrorAlert'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    buttons: {
        paddingLeft: '38px',
    },
}))

function SubmitKyc({ changeStep, submitKyc, submitKycState }) {
    const classes = useStyles()
    const [kycFront, setKycFront] = useState({})
    const [kycInside, setKycInside] = useState({})

    const handleSubmit = () => {
        const formData = new FormData()

        formData.append('kycFront', kycFront)
        formData.append('kycInside', kycInside)

        submitKyc(formData)
    }

    return (
        <React.Fragment>
            <PageTitle>Upload your documents</PageTitle>

            {
                submitKycState.hasErrors &&
                <ErrorAlert title="Something went wrong while trying to upload your files">
                    {submitKycState.errorMessage}
                </ErrorAlert>
            }

            <div className={classes.root}>
                <Paper>
                    <PaperTitle>Passport</PaperTitle>

                    <PaperIntro>
                        Please choose a high quality scanned image or picture of your passport. You should provide one from the front (outside) and one from the first page of your passport with your picture and personal information clearly visible
                    </PaperIntro>

                        <div>
                            <FileUpload
                                caption="Choose file"
                                description="Passport front"
                                inputName="front"
                                id="uploadKycFront"
                                onChange={e => setKycFront(e)}
                                accept="application/pdf,image/*"
                            />
                        </div>

                        <div>
                            <FileUpload
                                caption="Choose file"
                                description="Passport inside"
                                inputName="inside"
                                id="uploadKycInside"
                                onChange={e => setKycInside(e)}
                                accept="application/pdf,image/*"
                            />
                        </div>
                </Paper>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item>
                        <Button
                            disabled={submitKycState.loading}
                            onClick={() => changeStep(1)}
                            startIcon={<FaCaretLeft />}
                        >
                            {submitKycState.loading ? "Please wait..." : "Previous"}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            disabled={submitKycState.loading}
                            onClick={() => handleSubmit()}
                            startIcon={<FaSave />}
                        >
                            {submitKycState.loading ? "Please wait..." : "Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default SubmitKyc
