import React from 'react'
import {
    Stepper,
    Step,
    StepLabel,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        backgroundColor: 'inherit',
    },
})

function VerificationStatus({ status, step }) {
    const classes = useStyles()
    let stage = 0
    let step1Label = ''
    let step2Label = ''

    switch (status) {
        case 'open':
            stage = 0
            step1Label = step === 1 ? 'Provide information' : 'Upload documents'
            step2Label = ''
            break
        case 'submitted':
            stage = 1
            step1Label = 'All information has been provided'
            step2Label = 'We are reviewing your submission'
            break
        case 'invalid':
            stage = 1
            step1Label = step === 1 ? 'Provide information' : 'Upload documents'
            step2Label = 'Your submission is rejected'
            break
        case 'valid':
            stage = 3
            step1Label = 'All information has been provided'
            step2Label = 'Your submission was approved'
            break
        default:
            stage = 0
            step1Label = ''
            step2Label = ''
    }

    return (
        <Stepper className={classes.root} activeStep={stage}>
            <Step>
                <StepLabel optional={
                    <Typography variant="caption">{step1Label}</Typography>
                }
                >
                    Profile
                </StepLabel>
            </Step>
            <Step>
                <StepLabel
                    error={status === 'invalid'}
                    optional={
                        <Typography
                            variant="caption"
                            color={status === 'invalid' ? 'error' : 'textPrimary'}
                        >
                            {step2Label}
                        </Typography>
                    }
                >
                    Review
                </StepLabel>
            </Step>
            <Step><StepLabel>Approved</StepLabel></Step>
        </Stepper>
    )
}

export default VerificationStatus
