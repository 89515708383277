import React, { useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import Navigation from "./components/Navigation"
import Routes from "./components/Routes"
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'
import { makeStyles } from '@material-ui/core/styles'
import { fetchCompany } from "./actions/company"
import { fetchProfile } from "./actions/profile"

const useStyles = makeStyles({
    root: {
        marginTop: '20px',
    },
})

function App({
    isValidSession,
    profileState,
    companyState,
    fetchProfile,
    fetchCompany,
}) {
    const classes = useStyles()

    useEffect(() => {
        if (isValidSession) {
            fetchProfile()
            fetchCompany()
        }
    }, [fetchProfile, fetchCompany, isValidSession])

    return (
        <ThemeProvider theme={theme}>
            <Router forceRefresh={true}>
                <Navigation
                    isValidSession={isValidSession}
                    profileState={profileState}
                    companyState={companyState}
                >
                    <Container className={classes.root}>
                        <Grid container justify="center" alignItems="center">
                            <Routes isValidSession={isValidSession} />
                        </Grid>
                    </Container>
                </Navigation>
            </Router>
        </ThemeProvider>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProfile: () => dispatch(fetchProfile()),
        fetchCompany: () => dispatch(fetchCompany()),
    }
}

const mapStateToProps = state => ({
    isValidSession: state.signIn.isValidSession,
    profileState: state.profile,
    companyState: state.company,
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
