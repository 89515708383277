import React, { useState } from "react"
import Spinner from "../../components/Spinner"
import ProfileForm from "../../components/Profile/ProfileForm"
import ErrorAlert from "../../components/Common/Alerts/ErrorAlert"
import SubmitKyc from "../../components/Profile/SubmitKyc"
import VerificationStatus from "../../components/Common/Verification/VerificationStatus"
import VerificationApproved from "../../components/Common/Verification/VerificationApproved"
import VerificationPending from "../../components/Common/Verification/VerificationPending"
import VerificationRejected from "../../components/Common/Verification/VerificationRejected"

function ProfileView({
    saveProfile,
    submitKyc,
    profile,
    saveProfileState,
    submitKycState,
    resetKyc,
    resetKycState,
}) {
    const [step, setStep] = useState(1)
    const changeStep = step => setStep(step)

    return (
        <React.Fragment>
            <VerificationStatus status={profile.kycStatus} step={step} />
            {
                profile.hasErrors ?
                    <ErrorAlert title="Oops... An error occured :-(">
                        Could not load your user profile...
                    </ErrorAlert>
                    :
                    profile.loading ?
                        <Spinner />
                        :
                        profile.kycStatus === 'open' ?
                            step === 1 ?
                                <ProfileForm
                                    profile={profile}
                                    saveProfileState={saveProfileState}
                                    saveProfile={saveProfile}
                                    changeStep={changeStep}
                                />
                                :
                                <SubmitKyc
                                    changeStep={changeStep}
                                    submitKyc={submitKyc}
                                    submitKycState={submitKycState}
                                />
                            :
                            profile.kycStatus === 'submitted' ?
                                <VerificationPending
                                    submission="KYC"
                                    message="We are reviewing your submission, which will typically take a couple of days to complete. Once it's done, you will receive an email. Please keep an eye on your email or check back later on this page"
                                />
                                :
                                profile.kycStatus === 'invalid' ?
                                    <VerificationRejected
                                        submission="KYC"
                                        message="Unfortunately, your submission was rejected. Please check the email we sent you for more information on why this happened and how to resubmit your application."
                                        reset={resetKyc}
                                        resetState={resetKycState}
                                    />
                                    :
                                    profile.kycStatus === 'valid' ?
                                        <VerificationApproved
                                            submission="KYC"
                                            message="You can now use your account to add a company or create new product requests or offerings."
                                        />
                                        :
                                        <ErrorAlert title="Oops... An error occured :-(">
                                            Could not retrieve your KYC status...
                                        </ErrorAlert>
            }
        </React.Fragment>
    )
}

export default ProfileView
