import React from "react"
import noSearchResults from "../../images/noSearchResults.png"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import InfoAlert from "./Alerts/InfoAlert"

const useStyles = makeStyles(() => ({
    img: {
        marginBottom: '30px',
    },
}))

function NoResults() {
    const classes = useStyles()

    return (
        <Grid container justify="center">
            <Grid item md={2}></Grid>

            <Grid item md={8}>
                <Grid container justify="center">
                    <Grid item>
                        <img className={classes.img} width="250" src={noSearchResults} alt="No search results" />
                    </Grid>
                </Grid>

                <InfoAlert title="No search results found">
                    Please try another selection
                </InfoAlert>
            </Grid>

            <Grid item md={2}></Grid>
        </Grid>
    )
}

export default NoResults
