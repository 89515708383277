import { post } from '../services/http'
import { resetAfterActivation } from './registration'

export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE'

export const requestActivation = () => ({
    type: ACTIVATION_REQUEST,
})

export const successActivation = () => ({
    type: ACTIVATION_SUCCESS,
})

export const failureActivation = (errorMessage) => ({
    type: ACTIVATION_FAILURE,
    errorMessage: errorMessage,
})

export function fetchActivation(uuid, activationCode) {
    return async dispatch => {
        dispatch(requestActivation())

        try {
            const response = await post('/user/activate', {
                uuid: uuid,
                activationCode: activationCode,
            })

            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(resetAfterActivation())
                dispatch(successActivation())
            } else {
                dispatch(failureActivation(body.message))
            }
        } catch (e) {
            dispatch(failureActivation('Something went wrong. Please try again.'))
        }
    }
}
