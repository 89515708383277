import React, { useEffect } from "react"
import { fetchMyProducts } from "../../actions/myProducts"
import { fetchDeleteProduct } from "../../actions/deleteProduct"
import { connect } from "react-redux"
import MyProductListingsView from "../../views/ProductListingView/MyProductListingsView"

function MyProductListings({
    myProductsState,
    fetchMyProducts,
    deleteProduct,
    deleteProductState,
}) {
    useEffect(() => {
        fetchMyProducts()
    }, [fetchMyProducts])

    return (
        <MyProductListingsView
            myProducts={myProductsState}
            deleteProduct={deleteProduct}
            deleteProductState={deleteProductState}
        />
    )
}

const mapStateToProps = state => ({
    myProductsState: state.myProducts,
    deleteProductState: state.deleteProduct,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchMyProducts: () => dispatch(fetchMyProducts()),
        deleteProduct: uuid => dispatch(fetchDeleteProduct(uuid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProductListings)
