import React from "react"
import CheckBox from "../Common/Forms/CheckBox"
import ErrorAlert from "../Common/Alerts/ErrorAlert"
import TextField from "../Common/Forms/Inputs/TextField"
import CountrySelect from "../Common/Forms/CountrySelect"
import RadioGroup from '../Common/Forms/RadioGroup'
import SubmitButton from '../Common/Forms/Buttons/SubmitButton'
import Button from '../Common/Forms/Buttons/Button'
import PaperTitle from '../Common/Typography/PaperTitle'
import PageTitle from "../Common/Typography/PageTitle"
import Paper from "../Common/Paper"
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FaSave, FaCaretRight } from "react-icons/fa"
import { DatePicker } from 'formik-material-ui-pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
    Formik,
    Form,
    Field,
} from "formik"

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    datePicker: {
        width: '100%',
    },
    buttons: {
        paddingLeft: '38px',
    },
}))

function ProfileForm({ profile, saveProfileState, saveProfile, changeStep }) {
    const classes = useStyles()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PageTitle>Please complete your profile</PageTitle>

            <Formik
                initialValues={{
                    role: profile.role,
                    firstName: profile.firstName,
                    middleName: profile.middleName,
                    lastName: profile.lastName,
                    phoneNumber: profile.phoneNumber,
                    title: profile.title,
                    dateOfBirth: new Date(profile.dateOfBirth * 1000),
                    gender: profile.gender,
                    nationality: profile.nationality,
                    hasEntity: profile.hasEntity,
                    streetName: profile.streetName,
                    houseNumber: profile.houseNumber,
                    additionalAddressLine: profile.additionalAddressLine,
                    zipCode: profile.zipCode,
                    city: profile.city,
                    country: profile.country,
                }}
                validate={values => {
                    let errors = {}
                    const eighteenYearsAgo = new Date()
                    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

                    if (values.firstName === '') errors.firstName = 'First name is required'
                    if (values.lastName === '') errors.lastName = 'Last name is required'
                    if (values.phoneNumber === '') errors.phoneNumber = 'Phone number is required'
                    if (values.title === '') errors.title = 'Title is required'
                    if (values.gender === '')  errors.gender = 'Please select a gender'
                    if (values.nationality === '') errors.nationality = 'Please select a nationality'
                    if (values.streetName === '') errors.streetName = 'Street name is required'
                    if (values.houseNumber === '') errors.houseNumber = 'House number is required'
                    if (values.zipCode === '') errors.zipCode = 'Zip/postal code is required'
                    if (values.city === '') errors.city = 'City is required'
                    if (values.country === '') errors.country = 'Country is required'
                    if (values.dateOfBirth > eighteenYearsAgo) {
                        errors.dateOfBirth = 'You have to be at least 18 years or older'
                    }
                    if (values.role !== 'broker' && !values.hasEntity) {
                        errors.hasEntity = 'You must act directly from a corporate entity when you are not a broker' 
                    }
                    
                    return errors
                }}
                onSubmit={event => saveProfile(event)}
            >
                {({ handleBlur, handleChange, values, errors }) => (
                    <Form>
                        {saveProfileState.hasErrors &&
                            <ErrorAlert title="Something went wrong while trying to save your profile...">
                                {saveProfileState.errorMessage}
                            </ErrorAlert>
                        }
                        
                        <div className={classes.root}>
                            <Paper>
                                <PaperTitle>About yourself</PaperTitle>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={2}>
                                        <TextField inputName="title" label="Title" value={values.title} required />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField inputName="firstName" label="First name" value={values.firstName} required />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField inputName="middleName" label="Middle name" value={values.middleName} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField inputName="lastName" label="Last name" value={values.lastName} required />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CountrySelect
                                            label="Please select your nationality"
                                            value={values.nationality}
                                            name="nationality"
                                            required
                                            error={errors.nationality}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={DatePicker}
                                            name="dateOfBirth"
                                            label="Date of birth"
                                            className={classes.datePicker}
                                            margin="dense"
                                            format="MMMM dd, yyyy"
                                            error={errors.dateOfBirth}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            label="Your gender:"
                                            selectedValue={values.gender}
                                            inputName="gender"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            options={[
                                                { label: 'Male', value: 'M' },
                                                { label: 'Female', value: 'F' },
                                                { label: 'Other', value: 'O' },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper>
                                <PaperTitle>Contact information</PaperTitle>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} md={9} lg={10}>
                                        <TextField inputName="streetName" label="Street name" value={values.streetName} required />
                                    </Grid>
                                    <Grid item xs={4} md={3} lg={2}>
                                        <TextField inputName="houseNumber" label="House number" value={values.houseNumber} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            inputName="additionalAddressLine"
                                            label="Additional address line"
                                            value={values.additionalAddressLine}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField inputName="zipCode" label="Zip/postal code" value={values.zipCode} required />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField inputName="city" label="City" value={values.city} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CountrySelect
                                            label="Country"
                                            value={values.country}
                                            name="country"
                                            required
                                            error={errors.country}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField inputName="phoneNumber" label="Phone number" value={values.phoneNumber} required />
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper>
                                <RadioGroup
                                    label="Your role:"
                                    selectedValue={values.role}
                                    inputName="role"
                                    options={[
                                        { label: 'Buyer', value: 'buyer' },
                                        { label: 'Seller', value: 'seller' },
                                        { label: 'Broker', value: 'broker' },
                                    ]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div>
                                    <CheckBox
                                        inputName="hasEntity"
                                        label="I act through a corporate entity"
                                        selectedValue={values.hasEntity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.hasEntity}
                                    />
                                </div>
                            </Paper>

                            <Grid container spacing={2} className={classes.buttons}>
                                <Grid item>
                                    <SubmitButton
                                        disabled={saveProfileState.loading}
                                        startIcon={<FaSave />}
                                    >
                                        {saveProfileState.loading ? "Please wait..." : "Save"}
                                    </SubmitButton>
                                </Grid>

                                <Grid item>
                                    <Button
                                        disabled={!profile.isReadyToSubmitKyc || saveProfileState.loading}
                                        onClick={() => changeStep(2)}
                                        endIcon={<FaCaretRight />}
                                    >
                                        {saveProfileState.loading ? "Please wait..." : "Next"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    )
}

export default ProfileForm
