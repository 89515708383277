import React from "react"
import MyProductListingOverview from "../../components/ProductListing/MyProductListingOverview"
import Spinner from "../../components/Spinner"
import ErrorAlert from "../../components/Common/Alerts/ErrorAlert"

function MyProductListingView({
    productUuid,
    profile,
    myProducts,
    createProductListingState,
    submitVerification,
    submitVerificationState,
    fetchResetVerification,
    resetVerificationState,
}) {
    const hasProducts = !!myProducts.items && myProducts.items.length > 0
    const product = hasProducts && myProducts.items.find(p => p.uuid === productUuid)
    const resetVerification = () => fetchResetVerification(product ? product.uuid : null)

    return (
        <React.Fragment>
            {
                (profile.hasErrors || myProducts.hasErrors || !hasProducts || !product) ?
                    <ErrorAlert title="Something went wrong while trying to load this page">
                        <ul>
                            {profile.hasErrors && <li>Could not load user profile</li>}
                            {myProducts.hasErrors && <li>Could not load products</li>}
                            {(!hasProducts || !product) && <li>You did not create this product, or this product does not exist</li>}
                        </ul>
                    </ErrorAlert>
                    :
                    (profile.loading || myProducts.loading || createProductListingState.isCreated) ?
                        <Spinner />
                        :
                        <MyProductListingOverview
                            product={product}
                            profile={profile}
                            submitVerification={submitVerification}
                            submitVerificationState={submitVerificationState}
                            resetVerification={resetVerification}
                            resetVerificationState={resetVerificationState}
                        />
            }
        </React.Fragment>
    )
}

export default MyProductListingView
