import React from "react"
import SignInForm from "../../components/SignIn/SignInForm"
import { Grid } from '@material-ui/core'
import Paper from "../../components/Common/Paper"
import PaperTitle from "../../components/Common/Typography/PaperTitle"
import Link from "../../components/Navigation/Link"
import PageTitle from "../../components/Common/Typography/PageTitle"
import SuccessAlert from "../../components/Common/Alerts/SuccessAlert"

function SignInView({ signInState, signInUser, registrationState }) {    
    return (
        <React.Fragment>
            <PageTitle>Sign in</PageTitle>

            <Grid container justify="center" alignItems="center">
                <Grid item xs={10} md={9} lg={6}>
                    <Paper>
                        <PaperTitle>Or <Link to="/register">register</Link> an account</PaperTitle>

                        {
                            registrationState.isRegistered &&
                            <SuccessAlert title="Account registration was successful">
                                We sent you an email with instructions on how to verify your email
                                address in order to activate your account. Please also check your spam or
                                junk folder in case you cannot immediately find it.
                            </SuccessAlert>
                        }

                        <SignInForm signInState={signInState} signInUser={signInUser} />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SignInView
