import * as actions from '../actions/signIn'

export const initialState = {
    jwt: '',
    isValidSession: false,
    loading: false,
    hasErrors: false,
    errorMessage: '',
}

export default function signInReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SIGN_IN_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.SIGN_IN_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                jwt: '',
                isValidSession: false,
                errorMessage: action.errorMessage,
            }
        case actions.SIGN_IN_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                jwt: action.jwt,
                isValidSession: true,
                errorMessage: '',
            }
        case actions.SIGN_OUT:
            return {
                ...state,
                jwt: '',
                isValidSession: false,
            }
        default:
            return state
    }
}