import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"

const useStyles = makeStyles({
    textField: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
    },
})

function ReadOnly({ label, value }) {
    const classes = useStyles()

    return (
        <TextField
            className={classes.textField}
            type="text"
            label={label}
            value={value}
            InputProps={{
                readOnly: true,
            }}
            disabled
        />
    )
}

export default ReadOnly
