import React from "react"
import { Grid } from "@material-ui/core"
import Paper from "../Paper"
import ErrorAlert from "../Alerts/ErrorAlert"
import PaperTitle from '../Typography/PaperTitle'
import { makeStyles } from "@material-ui/core/styles"
import document from "../../../images/documentRejected.png"
import Button from "../Forms/Buttons/Button"
import { MdRefresh } from "react-icons/md"

const useStyles = makeStyles(() => ({
    img: {
        marginBottom: '30px',
    },
    button: {
        paddingLeft: '38px',
    },
    paperTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}))

function VerificationRejected({ submission, message, reset, resetState }) {
    const classes = useStyles()

    return (
        <Grid container justify="center">
            <Grid item md={2}></Grid>

            <Grid item md={8}>
                <Paper>
                    <Grid container justify="center">
                        <Grid item>
                            <img className={classes.img} width="250" src={document} alt="Documents rejected" />
                        </Grid>
                    </Grid>

                    <PaperTitle className={classes.paperTitle}>
                        Your {submission} submission was rejected
                    </PaperTitle>

                    <ErrorAlert title="We found something wrong or missing in the information you provided">
                        {message}
                    </ErrorAlert>
                </Paper>

                <div className={classes.button}>
                    <Button
                        startIcon={<MdRefresh />}
                        onClick={reset}
                        disabled={resetState.loading}
                    >
                        Reset {submission}
                    </Button>
                </div>
            </Grid>

            <Grid item md={2}></Grid>
        </Grid>
    )
}

export default VerificationRejected
