import React, { useEffect } from "react"
import SearchProductListingsView from "../../views/ProductListingView/SearchProductListingsView"
import { fetchFilteredProducts } from "../../actions/filteredProducts"
import { connect } from "react-redux"
import { fetchCategories } from "../../actions/categories"
import { fetchProductTypes } from "../../actions/productTypes"

function SearchProductListings({
    fetchFilteredProducts,
    fetchCategories,
    fetchProductTypes,
    filteredProducts,
    categories,
    productTypes,
}) {
    const isShippingIncluded = filteredProducts.isShippingIncluded
    const category = filteredProducts.category
    const productType = filteredProducts.productType
    const listingType = filteredProducts.listingType

    useEffect(() => {
        fetchFilteredProducts(
            isShippingIncluded,
            category,
            productType,
            listingType,
        )
        fetchCategories()
        fetchProductTypes()
    }, [
            fetchFilteredProducts,
            fetchCategories,
            fetchProductTypes,
            isShippingIncluded,
            category,
            productType,
            listingType,
    ])

    return (
        <SearchProductListingsView
            filteredProducts={filteredProducts}
            fetchFilteredProducts={fetchFilteredProducts}
            categories={categories}
            productTypes={productTypes}
        />
    )
}

const mapStateToProps = state => ({
    filteredProducts: state.filteredProducts,
    categories: state.categories,
    productTypes: state.productTypes,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchFilteredProducts: (
            isShippingIncluded,
            category,
            productType,
            listingType,
        ) => dispatch(fetchFilteredProducts(
            isShippingIncluded,
            category,
            productType,
            listingType,
        )),
        fetchCategories: () => dispatch(fetchCategories()),
        fetchProductTypes: () => dispatch(fetchProductTypes()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchProductListings)
