import React from "react"
import { Field } from "formik"
import { TextField } from 'formik-material-ui'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    textField: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
    },
})

function EmailField({ label, inputName, value, enableAutoComplete, required }) {
    const classes = useStyles()
    
    return (
        <Field
            className={classes.textField}
            component={TextField}
            type="email"
            name={inputName}
            label={label}
            value={value}
            required={required}
            autoComplete={enableAutoComplete ? 'username' : 'new-password'}
        />
    )
}

export default EmailField
