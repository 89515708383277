import React from "react"
import SearchProductListings from "../../components/ProductListing/SearchProductListings"
import Spinner from "../../components/Spinner"

function SearchProductListingsView({
    filteredProducts,
    fetchFilteredProducts,
    categories,
    productTypes,
}) {
    return (
        <React.Fragment>
            {
                filteredProducts.loading ?
                    <Spinner />
                    :
                    <SearchProductListings
                        filteredProducts={filteredProducts}
                        fetchFilteredProducts={fetchFilteredProducts}
                        categories={categories}
                        productTypes={productTypes}
                    />
            }
        </React.Fragment>
    )
}

export default SearchProductListingsView
