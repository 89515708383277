import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
    title: {
        marginBottom: '10px',
    },
    shadow: {
        boxShadow: 'inset 0 -8px 0px rgba(27,108,168,0.2)',
    },
}))

function PaperTitle({ children, ...props }) {
    const classes = useStyles()

    return (
        <Typography variant="h5" className={classes.title} {...props}>
            <span className={classes.shadow}>{children}</span>
        </Typography>
    )
}

export default PaperTitle
