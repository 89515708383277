import { uploadFiles } from '../services/http'
import { fetchMyProducts } from './myProducts'

export const SUBMIT_VERIFICATION_REQUEST = 'SUBMIT_VERIFICATION_REQUEST'
export const SUBMIT_VERIFICATION_SUCCESS = 'SUBMIT_VERIFICATION_SUCCESS'
export const SUBMIT_VERIFICATION_FAILURE = 'SUBMIT_VERIFICATION_FAILURE'

export const requestSubmitVerification = () => ({
    type: SUBMIT_VERIFICATION_REQUEST,
})

export const successSubmitVerification = () => ({
    type: SUBMIT_VERIFICATION_SUCCESS,
})

export const failureSubmitVerification = (errorMessage) => ({
    type: SUBMIT_VERIFICATION_FAILURE,
    errorMessage: errorMessage,
})

export function fetchSubmitVerification(files, uuid) {
    return async dispatch => {
        dispatch(requestSubmitVerification())

        try {
            const response = await uploadFiles(`/product/verify/${uuid}`, files)
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(successSubmitVerification())
                dispatch(fetchMyProducts())
            } else {
                dispatch(failureSubmitVerification(body.message))
            }
        } catch (e) {
            dispatch(failureSubmitVerification('Something went wrong'))
        }
    }
}
