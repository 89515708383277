import * as actions from '../actions/filteredProducts'

export const initialState = {
    items: [],
    isShippingIncluded: false,
    category: '',
    productType: '',
    listingType: '',
    loading: true,
    hasErrors: false,
    errorMessage: '',
}

export default function filteredProductsReducer(state = initialState, action) {
    switch (action.type) {
        case actions.FILTERED_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
                isShippingIncluded: action.isShippingIncluded,
                category: action.category,
                productType: action.productType,
                listingType: action.listingType,
            }
        case actions.FILTERED_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                errorMessage: action.errorMessage,
                items: [],
            }
        case actions.FILTERED_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                errorMessage: '',
                items: action.payload || [],
            }
        default:
            return state
    }
}
