import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Divider } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    separator: {
        marginTop: '25px',
    },
}))

function PaperSeparator() {
    const classes = useStyles()

    return (
        <Divider className={classes.separator} />
    )
}

export default PaperSeparator
