import * as actions from '../actions/submitVerification'

export const initialState = {
    loading: false,
    hasErrors: false,
    errorMessage: '',
}

export default function submitVerificationReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SUBMIT_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.SUBMIT_VERIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                errorMessage: action.errorMessage,
            }
        case actions.SUBMIT_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                errorMessage: '',
            }
        default:
            return state
    }
}
