import React from "react"

function PaperIntro({ children }) {

    return (
        <span>
            {children}
        </span>
    )
}

export default PaperIntro