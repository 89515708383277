import React, { useState } from "react"
import {
    FormControl,
    FormLabel,
    RadioGroup as MUIRadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    radioGroup: {
        marginTop: '25px',
        marginRight: '40px',
    },
})

function RadioGroup({ label, selectedValue, options, inputName, onChange, onBlur, disabled }) {
    const classes = useStyles()
    const [value, setValue] = useState(selectedValue)

    const handleChange = (event, newValue) => {
        setValue(newValue)
        onChange(event, newValue)
    }

    return (
        <FormControl component="fieldset" className={classes.radioGroup} disabled={disabled}>
            <FormLabel component="legend">{label}</FormLabel>
            <MUIRadioGroup
                row
                aria-label={inputName}
                name={inputName}
                value={value || ''}
                onChange={handleChange}
                onBlur={onBlur}
            >
                {options.map((radio, i) => (
                    <FormControlLabel
                        value={radio.value}
                        control={<Radio />}
                        label={radio.label}
                        key={i}
                    />
                ))}
            </MUIRadioGroup>
        </FormControl>
    )
}

export default RadioGroup
