import React, { useState } from "react"
import Spinner from "../../components/Spinner"
import CompanyForm from "../../components/Company/CompanyForm"
import ErrorAlert from "../../components/Common/Alerts/ErrorAlert"
import SubmitKyb from "../../components/Company/SubmitKyb"
import VerificationStatus from "../../components/Common/Verification/VerificationStatus"
import VerificationApproved from "../../components/Common/Verification/VerificationApproved"
import VerificationPending from "../../components/Common/Verification/VerificationPending"
import VerificationRejected from "../../components/Common/Verification/VerificationRejected"

function CompanyView({
    saveCompany,
    submitKyb,
    company,
    saveCompanyState,
    submitKybState,
    resetKybState,
    resetKyb,
}) {
    const [step, setStep] = useState(1)
    const changeStep = step => setStep(step)

    return (
        <React.Fragment>
            <VerificationStatus status={company.kybStatus} step={step} />
            {
                company.hasErrors ?
                    <ErrorAlert title="Oops... An error occured :-(">
                        Could not load company profile...
                    </ErrorAlert>
                    :
                    company.loading ?
                        <Spinner />
                        :
                        !company.name || company.kybStatus === 'open' ? 
                            step === 1 ?
                                <CompanyForm
                                    company={company}
                                    saveCompanyState={saveCompanyState}
                                    saveCompany={saveCompany}
                                    changeStep={changeStep}
                                />
                                :
                                <SubmitKyb
                                    changeStep={changeStep}
                                    submitKyb={submitKyb}
                                    submitKybState={submitKybState}
                                />
                            :
                            company.kybStatus === 'submitted' ?
                                <VerificationPending
                                    submission="KYB"
                                    message="We are reviewing your submission, which will typically take a couple of days to complete. Once it's done, you will receive an email. Please keep an eye on your email or check back later on this page"
                                />
                                :
                                company.kybStatus === 'invalid' ?
                                    <VerificationRejected
                                        submission="KYB"
                                        message="Unfortunately, your submission was rejected. Please check the email we sent you for more information on why this happened and how to resubmit your application."
                                        reset={resetKyb}
                                        resetState={resetKybState}
                                    />
                                    :
                                    company.kybStatus === 'valid' ?
                                        <VerificationApproved
                                            submission="KYB"
                                            message="You can now use your account to create new product requests or offerings."
                                        />
                                        :
                                        <ErrorAlert title="Oops... An error occured :-(">
                                            Could not retrieve your KYB status...
                                        </ErrorAlert>
            }
        </React.Fragment>
    )
}

export default CompanyView
