import { post } from '../services/http'

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'

// only alters state, so only one action needed
export const SWITCH_ROLE = 'SWITCH_ROLE'
export const RESET_AFTER_ACTIVATION = 'RESET_AFTER_ACTIVATION'

export const requestRegistration = () => ({
    type: REGISTRATION_REQUEST,
})

export const successRegistration = () => ({
    type: REGISTRATION_SUCCESS,
})

export const failureRegistration = errorMessage => ({
    type: REGISTRATION_FAILURE,
    errorMessage: errorMessage,
})

export const switchRole = role => ({
    type: SWITCH_ROLE,
    role: role,
})

export const resetAfterActivation = () => ({
    type: RESET_AFTER_ACTIVATION,
})

export function fetchRegistration(emailAddress, password, role) {
    return async dispatch => {
        dispatch(requestRegistration())

        try {
            const response = await post('/user/register', {
                emailAddress: emailAddress,
                password: password,
                role: role,
            })

            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(successRegistration())
            } else {
                dispatch(failureRegistration(body.message))
            }
        } catch (e) {
            dispatch(failureRegistration('Something went wrong. Please try again.'))
        }
    }
}
