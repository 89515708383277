import React from "react"
import SuccessAlert from '../../components/Common/Alerts/SuccessAlert'
import Link from '../../components/Navigation/Link'
import Paper from "../../components/Common/Paper"
import PaperTitle from "../../components/Common/Typography/PaperTitle"
import { Grid } from "@material-ui/core"
import unlocked from "../../images/unlocked.png"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
    img: {
        marginBottom: '30px',
    },
    paperTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}))

function ActivationSuccess() {
    const classes = useStyles()

    return (
        <Grid container justify="center">
            <Grid item md={2}></Grid>

            <Grid item md={8}>
                <Paper>
                    <Grid container justify="center">
                        <Grid item>
                            <img className={classes.img} width="250" src={unlocked} alt="Activation successful" />
                        </Grid>
                    </Grid>

                    <PaperTitle className={classes.paperTitle}>
                        You can now <Link to="/signin">sign in</Link>
                    </PaperTitle>

                    <SuccessAlert title="Thank you for verifying your email address">
                        This is the first step towards complete access to the portal.
                        Now <Link to="/signin">sign in</Link> to complete your profile to get started.
                    </SuccessAlert>
                </Paper>
            </Grid>

            <Grid item md={2}></Grid>
        </Grid>
    )
}

export default ActivationSuccess
