import React from "react"
import SignInView from "../../views/SignInView"
import { connect } from "react-redux"
import { fetchSignIn } from "../../actions/signIn"
import { Redirect } from "react-router-dom"

function SignIn({ fetchSignIn, signInState, registrationState }) {
    const signInUser = (values, resetForm) => {
        fetchSignIn(values.email, values.password)

        if (signInState.hasErrors) {
            resetForm(values)
        } else {
            resetForm({})
        }
    }

    return (
        <React.Fragment>
            {
                signInState.isValidSession ?
                    <Redirect to="/products/search" />
                    :
                    <SignInView
                        signInState={signInState}
                        signInUser={signInUser}
                        registrationState={registrationState}
                    />
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    signInState: state.signIn,
    registrationState: state.registration,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchSignIn: (emailAddress, password) => dispatch(fetchSignIn(emailAddress, password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
