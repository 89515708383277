import React, { useState } from "react"
import { countries } from "./countries"
import { TextField, FormHelperText, FormControl } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useField } from 'formik'

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
const countryToFlag = isoCode => {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode
}

const useStyles = makeStyles(theme => ({
    errorText: {
        color: theme.palette.error.main,
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    formControl: {
        width: '100%',
    },
}))

function CountrySelect({ label, inputName, value, required, error, ...props }) {
    const classes = useStyles()
    const [inputValue, setInputValue] = useState(value)
    const [field, , helpers] = useField(props)

    const handleChange = newValue => {
        try {
            setInputValue(newValue.code)
            helpers.setValue(newValue.code)
        } catch (e) {
            setInputValue('')
            helpers.setValue('')
        }
    }

    return (
        <FormControl
            label={label}
            className={classes.formControl}
            error={!!error}
            required={required}

        >
            <Autocomplete
                {...field}
                onBlur={e => helpers.setTouched(e)}
                onChange={(e, v) => handleChange(v)}
                style={{ width: '100%' }}
                options={countries}
                classes={{option: classes.option}}
                autoHighlight
                value={countries.find(country => country.code === inputValue) || ''}
                getOptionLabel={option => typeof option === 'string' ? option : option.label}
                renderOption={(option) => (
                    <React.Fragment>
                        <span>{countryToFlag(option.code)}</span>
                        {option.label} ({option.code})
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        required={required}
                        {...params}
                        label={label}
                        margin="dense"
                        name={inputName}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        </FormControl>
    )
}

export default CountrySelect
