import React, { useEffect } from "react"
import CreateProductListingView from "../../views/ProductListingView/CreateProductListingView"
import { fetchCreateProductListing } from "../../actions/createProductListing"
import { connect } from "react-redux"
import { fetchProductTypes } from "../../actions/productTypes"
import { fetchCategories } from "../../actions/categories"
import { fetchProfile } from "../../actions/profile"

function CreateProductListing({
    fetchCreateProductListing,
    createProductListingState,
    profile,
    fetchProfile,
    categories,
    productTypes,
    fetchCategories,
    fetchProductTypes
}) {
    useEffect(() => {
        fetchProfile()
        fetchCategories()
        fetchProductTypes()
    }, [fetchCategories, fetchProductTypes, fetchProfile])

    return (
        <CreateProductListingView
            createProductListing={fetchCreateProductListing}
            createProductListingState={createProductListingState}
            profile={profile}
            categories={categories}
            productTypes={productTypes}
        />
    )
}

const mapStateToProps = state => ({
    profile: state.profile,
    createProductListingState: state.createProductListing,
    categories: state.categories,
    productTypes: state.productTypes,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchCreateProductListing: payload => dispatch(fetchCreateProductListing(payload)),
        fetchCategories: () => dispatch(fetchCategories()),
        fetchProductTypes: () => dispatch(fetchProductTypes()),
        fetchProfile: () => dispatch(fetchProfile()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductListing)
