import { postAuth } from '../services/http'
import { fetchMyProducts } from './myProducts'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE'

export const requestDeleteProduct = () => ({
    type: DELETE_PRODUCT_REQUEST,
})

export const successDeleteProduct = () => ({
    type: DELETE_PRODUCT_SUCCESS,
})

export const failureDeleteProduct = (errorMessage) => ({
    type: DELETE_PRODUCT_FAILURE,
    errorMessage: errorMessage,
})

export function fetchDeleteProduct(uuid) {
    return async dispatch => {
        dispatch(requestDeleteProduct())

        try {
            const response = await postAuth(`/product/delete/${uuid}`, {})
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(fetchMyProducts())
                dispatch(successDeleteProduct())
            } else {
                dispatch(failureDeleteProduct(body.message))
            }
        } catch (e) {
            dispatch(failureDeleteProduct('Something went wrong'))
        }
    }
}
