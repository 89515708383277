import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from "@material-ui/core"

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
    },
})

function DropDown({ label, id, onChange, selectedValue, options, onBlur, required, error, disabled }) {
    const classes = useStyles()
    const labelId = `${id}-label`
    const [value, setValue] = useState(selectedValue)

    const handleChange = (event, newValue) => {
        setValue(newValue.props.value)
        onChange(event, newValue.props.value)
    }

    return (
        <FormControl className={classes.formControl} required={required} error={!!error} disabled={disabled}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                name={id}
                labelId={labelId}
                id={id}
                value={value || ''}
                onChange={handleChange}
                onBlur={onBlur}
            >
                {options.map((option, key) => (
                    <MenuItem key={key} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}

export default DropDown