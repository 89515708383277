import React, { useState } from "react"
import {
    Grid,
    DialogContentText,
    TextField,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DropDown from "../Common/Forms/DropDown"
import MyProductSnippet from "./MyProductSnippet"
import BiButtonDialog from "../Common/BiButtonDialog"
import NoResults from "../Common/NoResults"
import FullPageLoader from "../Common/FullPageLoader"

const useStyles = makeStyles({
    textField: {
        width: '100%',
    },
})

function MyProductListings({ myProducts, deleteProduct, deleteProductState }) {
    const [value, setValue] = useState("open")
    const [product, setProduct] = useState(null)
    const [confirmDelete, setConfirmDelete] = useState('')
    const classes = useStyles()
    const filteredProducts = filter => {
        return myProducts.items.filter(function (product) {
            return product.verificationStatus === filter
        })
    }
    const handleDelete = () => {
        if (confirmDelete.trim().toUpperCase() === 'I AM SURE') {
            deleteProduct(product.uuid)
        }
    }
    const handleClose = () => {
        setProduct(null)
        setConfirmDelete('')
    }

    return (
        <React.Fragment>
            <FullPageLoader open={deleteProductState.loading} />

            {
                product != null &&
                <BiButtonDialog
                    id="delete-my-product"
                    title={`Delete "${product.title}"?`}
                    onClickLeftBtn={() => handleDelete()}
                    leftBtnCaption="Delete"
                    rightBtnCaption="Cancel"
                    opened={product != null}
                    onClose={() => handleClose()}
                >
                    <DialogContentText id="delete-my-product">
                        Are you sure you want to delete your product listing titled "{product.title}"?
                        Please type "I AM SURE" and press "Delete" to confirm deletion.
                    </DialogContentText>

                    <TextField
                        className={classes.textField}
                        name="confirmDeleteMyProduct"
                        label="Confirm deletion"
                        onChange={e => setConfirmDelete(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </BiButtonDialog>
            }

            <Grid
                container
                direction="column"
                alignItems="stretch"
            >
                <Grid item xs={12} md={6} lg={4}>
                    <DropDown
                        label="Verification status"
                        id="verificationStatus"
                        onChange={(e, v) => setValue(v)}
                        selectedValue={value}
                        options={[
                            { label: 'Ready to Submit', value: 'open' },
                            { label: 'Pending Verification', value: 'submitted' },
                            { label: 'Rejected', value: 'invalid' },
                            { label: 'Verified', value: 'valid' },
                        ]}
                    />
                </Grid>

                <Grid
                    container
                    item
                    direction="row"
                    alignItems="stretch"
                >
                    {
                        filteredProducts(value).map((product, key) => (
                            <Grid item key={key} xs={12} md={6} lg={4}>
                                <MyProductSnippet
                                    product={product}
                                    onDeleteClick={() => setProduct(product)}
                                />
                            </Grid>
                        ))
                    }

                    {
                        filteredProducts(value).length === 0 &&
                        <NoResults />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default MyProductListings
