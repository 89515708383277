import React from "react"
import Spinner from "../../components/Spinner"
import { Redirect } from "react-router-dom"
import MyProductListings from "../../components/ProductListing/MyProductListings"

function MyProductListingsView({ myProducts, deleteProduct, deleteProductState }) {
    return (
        <React.Fragment>
            {
                myProducts.loading ?
                    <Spinner />
                    :
                    myProducts.items.length === 0 ?
                        <Redirect to="/product/new" />
                        :
                        <MyProductListings
                            myProducts={myProducts}
                            deleteProduct={deleteProduct}
                            deleteProductState={deleteProductState}
                        />
            }
        </React.Fragment>
    )
}

export default MyProductListingsView
