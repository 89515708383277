import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'activation',
        'saveProfile',
        'saveCompany',
        'registration',
        'submitKyc',
        'submitKyb',
        'resetKyc',
        'resetKyb',
        'createProductListing',
        'submitVerification',
        'createProductListing',
        'resetVerification',
        'deleteProduct',
    ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
)

export const persistor = persistStore(store)
