import React from "react"
import SubmitButton from '../Common/Forms/Buttons/SubmitButton'
import { Formik, Form } from "formik"
import EmailField from "../Common/Forms/Inputs/EmailField"
import PasswordField from "../Common/Forms/Inputs/PasswordField"
import ErrorAlert from "../Common/Alerts/ErrorAlert"

function SignInForm({ signInState, signInUser }) {
    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
                let errors = {}

                if (values.email === '') {
                    errors.email = "Email is required"
                }

                if (values.password === '') {
                    errors.password = "Password is required"
                }

                return errors
            }}
            onSubmit={(event, { resetForm }) => signInUser(event, resetForm)}
        >
            {() => (
                <Form>
                    {signInState.hasErrors &&
                        <ErrorAlert title="Could not sign you in">
                            {signInState.errorMessage}
                        </ErrorAlert>
                    }

                    <div>
                        <EmailField
                            inputName="email"
                            label="Email address"
                            enableAutoComplete
                            required
                        />
                    </div>
                    <div><PasswordField inputName="password" label="Password" enableAutoComplete required /></div>
                    <div>
                        <SubmitButton disabled={signInState.loading}>
                            {signInState.loading ? "Please wait..." : "Sign in"}
                        </SubmitButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default SignInForm
