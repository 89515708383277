import * as actions from '../actions/saveCompany'

export const initialState = {
    loading: false,
    hasErrors: false,
    errorMessage: '',
}

export default function saveCompanyReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SAVE_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.SAVE_COMPANY_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                errorMessage: action.errorMessage,
            }
        case actions.SAVE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                errorMessage: '',
            }
        default:
            return state
    }
}