import * as actions from '../actions/activation'

export const initialState = {
    loading: true,
    hasErrors: false,
    errorMessage: '',
}

export default function activationReducer(state = initialState, action) {
    switch (action.type) {
        case actions.ACTIVATION_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.ACTIVATION_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                errorMessage: action.errorMessage,
            }
        case actions.ACTIVATION_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                errorMessage: '',
            }
        default:
            return state
    }
}