import React from "react"
import { Link as MuiLink } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'none',
            boxShadow: 'inset 0 -8px 0px rgba(27,108,168,0.3)',
        },
    },
}))

function AnchorLink({ href, children, ...props }) {
    const classes = useStyles()

    return (
        <MuiLink href={href} className={classes.link} {...props}>
            {children}
        </MuiLink>
    )
}

export default AnchorLink
