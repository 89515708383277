import React, { useEffect } from "react"
import { connect } from "react-redux"
import { fetchProfile } from "../../actions/profile"
import { fetchSaveProfile } from "../../actions/saveProfile"
import { fetchSubmitKyc } from "../../actions/submitKyc"
import { Grid } from "@material-ui/core"
import ProfileView from "../../views/ProfileView"
import { fetchResetKyc } from "../../actions/resetKyc"

function Profile({
    fetchProfile,
    fetchSaveProfile,
    fetchSubmitKyc,
    fetchResetKyc,
    profile,
    saveProfileState,
    submitKycState,
    resetKycState,
}) {
    useEffect(() => {
        fetchProfile()
    }, [fetchProfile])

    const saveProfile = values => {
        const dateOfBirth = (
            new Date(values.dateOfBirth).getTime() / 1000
        ).toFixed(0)

        return fetchSaveProfile(
            values.role,
            values.firstName,
            values.middleName,
            values.lastName,
            values.phoneNumber,
            values.title,
            dateOfBirth,
            values.gender,
            values.nationality,
            values.hasEntity,
            values.streetName,
            values.houseNumber,
            values.additionalAddressLine,
            values.zipCode,
            values.city,
            values.country
        )
    }

    const submitKyc = files => fetchSubmitKyc(files)
    const resetKyc = () => fetchResetKyc()

    return (
        <React.Fragment>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <ProfileView
                    profile={profile}
                    saveProfileState={saveProfileState}
                    submitKycState={submitKycState}
                    saveProfile={saveProfile}
                    submitKyc={submitKyc}
                    resetKyc={resetKyc}
                    resetKycState={resetKycState}
                />
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    profile: state.profile,
    saveProfileState: state.saveProfile,
    submitKycState: state.submitKyc,
    resetKycState: state.resetKyc,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchSaveProfile: (
            role,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            title,
            dateOfBirth,
            gender,
            nationality,
            hasEntity,
            streetName,
            houseNumber,
            additionalAddressLine,
            zipCode,
            city,
            country
        ) => dispatch(fetchSaveProfile(
            role,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            title,
            dateOfBirth,
            gender,
            nationality,
            hasEntity,
            streetName,
            houseNumber,
            additionalAddressLine,
            zipCode,
            city,
            country
        )),
        fetchSubmitKyc: files => dispatch(fetchSubmitKyc(files)),
        fetchResetKyc: () => dispatch(fetchResetKyc()),
        fetchProfile: () => dispatch(fetchProfile()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
