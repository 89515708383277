import React from "react"
import ActivationError from "../../components/Activation/ActivationError"
import ActivationSuccess from "../../components/Activation/ActivationSuccess"
import Spinner from "../../components/Spinner"

function ActivationView({ activationState }) {
    return (
        activationState.loading ? <Spinner />
            :
            activationState.hasErrors ?
                <ActivationError message={activationState.errorMessage} />
                :
                <ActivationSuccess />
    )
}

export default ActivationView
