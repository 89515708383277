import React, { useEffect } from "react"
import MyProductListingView from "../../views/ProductListingView/MyProductListingView"
import { connect } from "react-redux"
import { fetchProfile } from "../../actions/profile"
import { fetchMyProducts } from "../../actions/myProducts"
import { fetchSubmitVerification } from "../../actions/submitVerification"
import { fetchResetVerification } from "../../actions/resetVerification"
import { Grid } from "@material-ui/core"

function MyProductListing({
    profile,
    myProducts,
    fetchProfile,
    fetchMyProducts,
    submitVerification,
    fetchResetVerification,
    createProductListingState,
    submitVerificationState,
    resetVerificationState,
    match,
}) {
    useEffect(() => {
        fetchProfile()
        fetchMyProducts()
    }, [fetchProfile, fetchMyProducts])

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
        >
            <MyProductListingView
                myProducts={myProducts}
                productUuid={match.params.uuid}
                profile={profile}
                createProductListingState={createProductListingState}
                submitVerification={submitVerification}
                submitVerificationState={submitVerificationState}
                fetchResetVerification={fetchResetVerification}
                resetVerificationState={resetVerificationState}
            />
        </Grid>
    )
}

const mapStateToProps = state => ({
    profile: state.profile,
    myProducts: state.myProducts,
    createProductListingState: state.createProductListing,
    submitVerificationState: state.submitVerification,
    resetVerificationState: state.resetVerification,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchProfile: () => dispatch(fetchProfile()),
        fetchMyProducts: () => dispatch(fetchMyProducts()),
        submitVerification: (files, uuid) => dispatch(fetchSubmitVerification(files, uuid)),
        fetchResetVerification: uuid => dispatch(fetchResetVerification(uuid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProductListing)
