import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import Paper from "../Common/Paper"
import { makeStyles } from "@material-ui/core/styles"
import PageTitle from "../Common/Typography/PageTitle"
import PaperTitle from "../Common/Typography/PaperTitle"
import PaperIntro from "../Common/Typography/PaperIntro"
import FileUpload from "../Common/Forms/Inputs/FileUpload"
import { FaSave, FaCaretLeft } from "react-icons/fa"
import Button from '../Common/Forms/Buttons/Button'
import ErrorAlert from '../Common/Alerts/ErrorAlert'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    buttons: {
        paddingLeft: '38px',
    },
}))

function SubmitKyb({ changeStep, submitKyb, submitKybState }) {
    const classes = useStyles()
    const [incorporationDoc, setIncorporationDoc] = useState({})

    const handleSubmit = () => {
        const formData = new FormData()
        formData.append('incorporationDoc', incorporationDoc)
        submitKyb(formData)
    }

    return (
        <React.Fragment>
            <PageTitle>Upload your documents</PageTitle>

            {
                submitKybState.hasErrors &&
                <ErrorAlert title="Something went wrong while trying to upload your files">
                    {submitKybState.errorMessage}
                </ErrorAlert>
            }

            <div className={classes.root}>
                <Paper>
                    <PaperTitle>Corporate entity verification</PaperTitle>

                    <PaperIntro>
                        Please choose a high quality scanned image or picture of your document(s). You should provide one for each page, including their back sides if they're printed.
                    </PaperIntro>

                    <div>
                        <FileUpload
                            caption="Choose file"
                            description="Incorporation document"
                            inputName="incorporationDoc"
                            id="uploadKyb"
                            onChange={e => setIncorporationDoc(e)}
                            accept="application/pdf,image/*"
                        />
                    </div>
                </Paper>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item>
                        <Button
                            disabled={submitKybState.loading}
                            onClick={() => changeStep(1)}
                            startIcon={<FaCaretLeft />}
                        >
                            {submitKybState.loading ? "Please wait..." : "Previous"}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            disabled={submitKybState.loading}
                            onClick={() => handleSubmit()}
                            startIcon={<FaSave />}
                        >
                            {submitKybState.loading ? "Please wait..." : "Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default SubmitKyb
