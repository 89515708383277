import * as actions from '../actions/registration'

export const initialState = {
    loading: false,
    hasErrors: false,
    errorMessage: '',
    isRegistered: false,
    role: '',
}

export default function registrationReducer(state = initialState, action) {
    switch (action.type) {
        case actions.REGISTRATION_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                isRegistered: false,
                errorMessage: '',
            }
        case actions.REGISTRATION_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                isRegistered: false,
                errorMessage: action.errorMessage,
            }
        case actions.REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                isRegistered: true,
                errorMessage: '',
            }
        case actions.SWITCH_ROLE:
            return {
                ...state,
                role: action.role,
            }
        case actions.RESET_AFTER_ACTIVATION:
            return {
                ...state,
                isRegistered: false,
            }
        default:
            return state
    }
}