import { getAuth } from '../services/http'

export const COMPANY_REQUEST = 'COMPANY_REQUEST'
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS'
export const COMPANY_FAILURE = 'COMPANY_FAILURE'
export const UPDATE_AFTER_SAVE_COMPANY = 'UPDATE_AFTER_SAVE_COMPANY'
export const UPDATE_AFTER_SUBMIT_KYB = 'UPDATE_AFTER_SUBMIT_KYB'
export const UPDATE_AFTER_RESET_KYB = 'UPDATE_AFTER_RESET_KYB'

export const requestCompany = () => ({
    type: COMPANY_REQUEST,
})

export const receiveCompany = payload => ({
    type: COMPANY_SUCCESS,
    payload: payload,
})

export const failureCompany = errorMessage => ({
    type: COMPANY_FAILURE,
    errorMessage: errorMessage,
})

export const updateAfterSubmitKyb = kybStatus => ({
    type: UPDATE_AFTER_SUBMIT_KYB,
    kybStatus: kybStatus,
})

export const updateAfterResetKyb = kybStatus => ({
    type: UPDATE_AFTER_RESET_KYB,
    kybStatus: kybStatus,
})

export const updateAfterSaveCompany = payload => ({
    type: UPDATE_AFTER_SAVE_COMPANY,
    payload: payload,
})

export function fetchCompany() {
    return async dispatch => {
        dispatch(requestCompany())

        try {
            const response = await getAuth('/company/profile')
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(receiveCompany(body.data))
            } else {
                dispatch(failureCompany(body.message))
            }
        } catch (e) {
            dispatch(failureCompany('Something went wrong'))
        }
    }
}
