import { postAuth } from '../services/http'

export const FILTERED_PRODUCTS_REQUEST = 'FILTERED_PRODUCTS_REQUEST'
export const FILTERED_PRODUCTS_SUCCESS = 'FILTERED_PRODUCTS_SUCCESS'
export const FILTERED_PRODUCTS_FAILURE = 'FILTERED_PRODUCTS_FAILURE'

export const requestFilteredProducts = (
    isShippingIncluded,
    category,
    productType,
    listingType,
) => ({
    type: FILTERED_PRODUCTS_REQUEST,
    isShippingIncluded: isShippingIncluded,
    category: category,
    productType: productType,
    listingType: listingType,
})

export const receiveFilteredProducts = payload => ({
    type: FILTERED_PRODUCTS_SUCCESS,
    payload: payload,
})

export const failureFilteredProducts = errorMessage => ({
    type: FILTERED_PRODUCTS_FAILURE,
    errorMessage: errorMessage,
})

export function fetchFilteredProducts(
    isShippingIncluded,
    category,
    productType,
    listingType,
) {
    return async dispatch => {
        dispatch(requestFilteredProducts(
            isShippingIncluded,
            category,
            productType,
            listingType,
        ))

        try {
            const response = await postAuth('/products/search', {
                isShippingIncluded: isShippingIncluded,
                category: category,
                productType: productType,
                listingType: listingType,
            })
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(receiveFilteredProducts(body.data))
            } else {
                dispatch(failureFilteredProducts(body.message))
            }
        } catch (e) {
            dispatch(failureFilteredProducts('Something went wrong'))
        }
    }
}
