import React, { useEffect } from "react"
import { connect } from "react-redux"
import { fetchCompany } from "../../actions/company"
import { fetchSaveCompany } from "../../actions/saveCompany"
import { fetchSubmitKyb } from "../../actions/submitKyb"
import { fetchResetKyb } from "../../actions/resetKyb"
import { Grid } from "@material-ui/core"
import CompanyView from "../../views/CompanyView"

function Company({
    fetchCompany,
    fetchSaveCompany,
    fetchSubmitKyb,
    company,
    saveCompanyState,
    submitKybState,
    fetchResetKyb,
    resetKybState,
}) {
    useEffect(() => {
        fetchCompany()
    }, [fetchCompany])

    const saveCompany = values => fetchSaveCompany(values)
    const submitKyb = files => fetchSubmitKyb(files)
    const resetKyb = () => fetchResetKyb()

    return (
        <React.Fragment>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <CompanyView
                    company={company}
                    saveCompanyState={saveCompanyState}
                    submitKybState={submitKybState}
                    saveCompany={saveCompany}
                    submitKyb={submitKyb}
                    resetKyb={resetKyb}
                    resetKybState={resetKybState}
                />
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    company: state.company,
    saveCompanyState: state.saveCompany,
    submitKybState: state.submitKyb,
    resetKybState: state.resetKyb,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchSaveCompany: payload => dispatch(fetchSaveCompany(payload)),
        fetchSubmitKyb: files => dispatch(fetchSubmitKyb(files)),
        fetchResetKyb: () => dispatch(fetchResetKyb()),
        fetchCompany: () => dispatch(fetchCompany()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Company)
