import React from "react"
import {
    Tabs,
    Tab,
    Grid,
} from '@material-ui/core'
import Paper from "../../components/Common/Paper"
import RegisterForm from "../../components/Register/RegisterForm"
import PaperTitle from "../../components/Common/Typography/PaperTitle"
import Link from "../../components/Navigation/Link"
import PageTitle from "../../components/Common/Typography/PageTitle"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    tabs: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginTop: '10px',
    },
    indicator: {
        height: '10px',
        marginTop: '-5px',
    },
    paper: {
        marginTop: '0px',
        padding: '20px',
        width: '100%',
    }
}))

function RegisterView({ registrationState, switchRole, registerUser }) {
    const classes = useStyles()

    return (
        <React.Fragment>
            <PageTitle>Register</PageTitle>

            <Grid container justify="center" alignItems="center">
                <Grid item xs={10} md={9} lg={6}>
                    <Tabs
                        value={registrationState.role}
                        onChange={(event, newValue) => switchRole(newValue)}
                        className={classes.tabs}
                        variant="fullWidth"
                        classes={{
                            root: classes.root,
                            indicator: classes.indicator,
                        }}
                    >
                        <Tab value="seller" label="Seller" />
                        <Tab value="broker" label="Broker" />
                        <Tab value="buyer" label="Buyer" />
                    </Tabs>
                    <Paper className={classes.paper}>
                        <PaperTitle>Or <Link to="/signin">sign in</Link> if you have an account</PaperTitle>

                        <RegisterForm registrationState={registrationState} registerUser={registerUser} />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default RegisterView
