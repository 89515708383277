import React, { useState } from "react"
import { Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    errorText: {
        color: theme.palette.error.main,
    }
}))

function CheckBox({ inputName, selectedValue, label, onChange, onBlur, error }) {
    const [value, setValue] = useState(selectedValue)
    const classes = useStyles()

    const handleChange = (event, newValue) => {
        setValue(newValue)
        onChange(event, newValue)
    }

    return (
        <React.Fragment>
            <FormControlLabel
                control={
                    <Checkbox
                        name={inputName}
                        checked={value}
                        onChange={handleChange}
                        onBlur={onBlur}
                    />
                }
                label={label}
            />
            <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        </React.Fragment>

    )
}

export default CheckBox
