import React from "react"
import Role from "../../components/Register/Role"
import Grid from '@material-ui/core/Grid'

function ChooseRole({ switchRole }) {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            <Role
                name="seller"
                description="I have items to sell that are directly in my possession"
                switchRole={switchRole}
            />

            <Role
                name="broker"
                description="
                    I have items to sell that are not directly in my possession or I want
                    to purchase items with funds that are not directly in my possession
                "
                switchRole={switchRole}
            />

            <Role
                name="buyer"
                description="I want to purchase items with funds that are directly in my possession"
                switchRole={switchRole}
            />
        </Grid>
    )
}

export default ChooseRole
