import React from "react"
import { Button } from "@material-ui/core"

function TextButton({ children, ...props }) {
    return (
        <Button color="secondary" {...props}>
            {children}
        </Button>
    )
}

export default TextButton
