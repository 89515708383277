import { post } from '../services/http'

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'

// only alters state, so only one action needed
export const SIGN_OUT = 'SIGN_OUT'

export const requestSignIn = () => ({
    type: SIGN_IN_REQUEST,
})

export const successSignIn = (jwt) => ({
    type: SIGN_IN_SUCCESS,
    jwt: jwt,
})

export const failureSignIn = (errorMessage) => ({
    type: SIGN_IN_FAILURE,
    errorMessage: errorMessage,
})

export const signOut = () => ({
    type: SIGN_OUT,
})

export function fetchSignIn(emailAddress, password) {
    return async dispatch => {
        dispatch(requestSignIn())

        try {
            const response = await post('/user/signin', {
                emailAddress: emailAddress,
                password: password
            })

            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(successSignIn(body.data.jwt))
            } else {
                dispatch(failureSignIn(body.message))
            }
        } catch (e) {
            dispatch(failureSignIn('Something went wrong'))
        }
    }
}
