import React, { useEffect } from "react"
import ActivationView from "../../views/ActivationView"
import { fetchActivation } from "../../actions/activation"
import { connect } from "react-redux"

function Activation({ dispatch, activationState, match }) {
    useEffect(() => {
        dispatch(fetchActivation(
            match.params.uuid,
            match.params.activationCode
        ))
    }, [dispatch, match])

    return (
        <ActivationView activationState={activationState} />
    )
}

const mapStateToProps = state => ({
    activationState: state.activation,
})

export default connect(mapStateToProps)(Activation)
