import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import Paper from "../Common/Paper"
import { makeStyles } from "@material-ui/core/styles"
import PageTitle from "../Common/Typography/PageTitle"
import PaperTitle from "../Common/Typography/PaperTitle"
import PaperIntro from "../Common/Typography/PaperIntro"
import FileUpload from "../Common/Forms/Inputs/FileUpload"
import { FaSave } from "react-icons/fa"
import Button from '../Common/Forms/Buttons/Button'
import ErrorAlert from '../Common/Alerts/ErrorAlert'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    buttons: {
        paddingLeft: '38px',
    },
}))

const images = { picture1: {}, picture2: {}, picture3: {} }
const documents = {
    letterOfIntentDoc: {},
    proofOfFundsDoc: {},
    poDoc: {},
    invoiceDoc: {},
    proofOfLifeDoc: {},
    certificateDoc: {},
    agreementDoc: {},
    inspectionReportDoc: {},
    originDoc: {},
}

const isFile = obj => obj instanceof File

function SubmitVerification({ product, profile, submitVerification, submitVerificationState }) {
    const [documentsError, setDocumentsError] = useState(false)
    const [pictureError, setPictureError] = useState(false)
    const classes = useStyles()
    const uploadOptions = []

    if (product.listingType === 'request') {
        if (profile.role === 'broker') {
            uploadOptions.push({label: 'Letter of intent', id: 'letterOfIntentDoc'})
        }

        uploadOptions.push({label: 'Proof of funds/Bank Comfort Letter', id: 'proofOfFundsDoc'})
    } else {
        if (product.actorRole === 'AUTHORIZED_DISTRIBUTOR' || product.actorRole === 'DISTRIBUTOR') {
            uploadOptions.push({ label: 'PO with factory or supplier', id: 'poDoc' })
            uploadOptions.push({ label: 'Invoice with factory or supplier', id: 'invoiceDoc' })
        }

        uploadOptions.push({ label: 'Proof of life', id: 'proofOfLifeDoc' })
        uploadOptions.push({ label: 'Certificate', id: 'certificateDoc' })
        uploadOptions.push({ label: 'Agreement with factory or supplier', id: 'agreementDoc' })
        uploadOptions.push({ label: 'Third party inspection report', id: 'inspectionReportDoc' })
        uploadOptions.push({ label: 'Certificate of origin', id: 'originDoc' })
    }

    const handleSubmit = e => {
        e.stopPropagation()

        if (product.listingType === 'request' && (
            (profile.role === 'broker' && !isFile(documents.letterOfIntentDoc)) ||
            !isFile(documents.proofOfFundsDoc)
        )) {
            setDocumentsError(true)
            return
        }

        if (product.listingType === 'offer' && ((
            (product.actorRole === 'AUTHORIZED_DISTRIBUTOR' || product.actorRole === 'DISTRIBUTOR') &&
            (!isFile(documents.poDoc) || !isFile(documents.invoiceDoc))) ||
            !isFile(documents.proofOfLifeDoc) ||
            !isFile(documents.certificateDoc) ||
            !isFile(documents.agreementDoc) ||
            !isFile(documents.inspectionReportDoc) ||
            !isFile(documents.originDoc)
        )) {
            setDocumentsError(true)
            return
        }

        setDocumentsError(false)

        if (
            !isFile(images.picture1) &&
            !isFile(images.picture2) &&
            !isFile(images.picture3)
        ) {
            setPictureError(true)
            return
        }

        setPictureError(false)

        const formData = new FormData()

        formData.append('letterOfIntentDoc', documents.letterOfIntentDoc)
        formData.append('proofOfFundsDoc', documents.proofOfFundsDoc)
        formData.append('poDoc', documents.poDoc)
        formData.append('invoiceDoc', documents.invoiceDoc)
        formData.append('proofOfLifeDoc', documents.proofOfLifeDoc)
        formData.append('certificateDoc', documents.certificateDoc)
        formData.append('agreementDoc', documents.agreementDoc)
        formData.append('inspectionReportDoc', documents.inspectionReportDoc)
        formData.append('originDoc', documents.originDoc)
        formData.append('picture1', images.picture1)
        formData.append('picture2', images.picture2)
        formData.append('picture3', images.picture3)
        
        submitVerification(formData, product.uuid)
    }

    return (
        <React.Fragment>
            <PageTitle>Upload your documents</PageTitle>

            {
                submitVerificationState.hasErrors &&
                <ErrorAlert title="Something went wrong while trying to upload your files">
                    {submitVerificationState.errorMessage}
                </ErrorAlert>
            }

            <div className={classes.root}>
                <Paper>
                    <PaperTitle>Product documentation</PaperTitle>

                    <PaperIntro>
                        Please provide all requested verification documents
                    </PaperIntro>

                    {
                        documentsError &&
                        <ErrorAlert title="Not all required files were selected">
                            Please select a file for each presented option
                        </ErrorAlert>
                    }

                    {uploadOptions.map((option, key) => (
                        <div key={key}>
                            <FileUpload
                                caption="Choose file"
                                description={option.label}
                                inputName={option.id}
                                id={option.id}
                                accept="application/pdf,image/*"
                                onChange={e => documents[option.id] = e}
                            />
                        </div>
                    ))}
                </Paper>
                <Paper>
                    <PaperTitle>Product images</PaperTitle>

                    <PaperIntro>
                        {
                            product.listingType === 'offer' ?
                                "Please choose minimum 1, maximum 3, high quality pictures of your product."
                                :
                                "Please choose minimum 1, maximum 3, high quality sample images of the product you are looking for."
                        }
                    </PaperIntro>
                
                    {
                        pictureError &&
                        <ErrorAlert title="You need to upload at least one picture"/>
                    }

                    <div>
                        <FileUpload
                            caption="Choose file"
                            description="Picture 1"
                            inputName="productPicture1Doc"
                            id="picture1"
                            accept="image/*"
                            onChange={e => images.picture1 = e}
                        />
                    </div>
                    <div>
                        <FileUpload
                            caption="Choose file"
                            description="Picture 2"
                            inputName="productPicture2Doc"
                            id="picture2"
                            accept="image/*"
                            onChange={e => images.picture2 = e}
                        />
                    </div>
                    <div>
                        <FileUpload
                            caption="Choose file"
                            description="Picture 3"
                            inputName="productPicture2Doc"
                            id="picture3"
                            accept="image/*"
                            onChange={e => images.picture3 = e}
                        />
                    </div>
                </Paper>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item>
                        <Button
                            disabled={submitVerification.loading}
                            startIcon={<FaSave />}
                            onClick={e => handleSubmit(e)}
                        >
                            {submitVerification.loading ? "Please wait..." : "Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default SubmitVerification
