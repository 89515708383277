import { combineReducers } from "redux"
import signInReducer from "./signInReducer"
import registrationReducer from "./registrationReducer"
import profileReducer from "./profileReducer"
import activationReducer from "./activationReducer"
import submitKycReducer from "./submitKycReducer"
import saveProfileReducer from "./saveProfileReducer"
import companyReducer from "./companyReducer"
import submitKybReducer from "./submitKybReducer"
import saveCompanyReducer from "./saveCompanyReducer"
import resetKycReducer from "./resetKycReducer"
import resetKybReducer from "./resetKybReducer"
import createProductListingReducer from "./createProductListingReducer"
import categoriesReducer from "./categoriesReducer"
import productTypesReducer from "./productTypesReducer"
import myProductsReducer from "./myProductsReducer"
import submitVerificationReducer from "./submitVerificationReducer"
import resetVerificationReducer from "./resetVerificationReducer"
import deleteProductReducer from "./deleteProductReducer"
import filteredProductsReducer from "./filteredProductsReducer"

const rootReducer = combineReducers({
    signIn: signInReducer,
    profile: profileReducer,
    registration: registrationReducer,
    activation: activationReducer,
    submitKyc: submitKycReducer,
    saveProfile: saveProfileReducer,
    company: companyReducer,
    submitKyb: submitKybReducer,
    saveCompany: saveCompanyReducer,
    resetKyc: resetKycReducer,
    resetKyb: resetKybReducer,
    createProductListing: createProductListingReducer,
    categories: categoriesReducer,
    productTypes: productTypesReducer,
    myProducts: myProductsReducer,
    submitVerification: submitVerificationReducer,
    resetVerification: resetVerificationReducer,
    deleteProduct: deleteProductReducer,
    filteredProducts: filteredProductsReducer,
})

export default rootReducer
