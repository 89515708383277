import * as actions from '../actions/productTypes'

export const initialState = {
    productTypes: [],
    loading: true,
    hasErrors: false,
    errorMessage: '',
}

export default function productTypesReducer(state = initialState, action) {
    switch (action.type) {
        case actions.PRODUCT_TYPES_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.PRODUCT_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                errorMessage: action.errorMessage,
                items: [],
            }
        case actions.PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                errorMessage: '',
                items: action.payload,
            }
        default:
            return state
    }
}
