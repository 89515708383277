import { getAuth } from '../services/http'

export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILURE = 'PROFILE_FAILURE'
export const UPDATE_AFTER_SAVE_PROFILE = 'UPDATE_AFTER_SAVE_PROFILE'
export const UPDATE_AFTER_SUBMIT_KYC = 'UPDATE_AFTER_SUBMIT_KYC'
export const UPDATE_AFTER_RESET_KYC = 'UPDATE_AFTER_RESET_KYC'

export const requestProfile = () => ({
    type: PROFILE_REQUEST,
})

export const receiveProfile = (
    uuid,
    role,
    emailAddress,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    title,
    dateOfBirth,
    gender,
    nationality,
    registrationDate,
    kycStatus,
    hasEntity,
    streetName,
    houseNumber,
    additionalAddressLine,
    zipCode,
    city,
    country,
    isReadyToSubmitKyc
) => ({
    type: PROFILE_SUCCESS,
    uuid: uuid,
    role: role,
    emailAddress: emailAddress,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    title: title,
    dateOfBirth: dateOfBirth,
    gender: gender,
    nationality: nationality,
    registrationDate: registrationDate,
    kycStatus: kycStatus,
    hasEntity: hasEntity,
    streetName: streetName,
    houseNumber: houseNumber,
    additionalAddressLine: additionalAddressLine,
    zipCode: zipCode,
    city: city,
    country: country,
    isReadyToSubmitKyc: isReadyToSubmitKyc,
})

export const failureProfile = errorMessage => ({
    type: PROFILE_FAILURE,
    errorMessage: errorMessage,
})

export const updateAfterSubmitKyc = (kycStatus, isReadyToSubmitKyc) => ({
    type: UPDATE_AFTER_SUBMIT_KYC,
    kycStatus: kycStatus,
    isReadyToSubmitKyc: isReadyToSubmitKyc,
})

export const updateAfterResetKyc = kycStatus => ({
    type: UPDATE_AFTER_RESET_KYC,
    kycStatus: kycStatus,
})

export const updateAfterSaveProfile = (
    role,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    title,
    dateOfBirth,
    gender,
    nationality,
    hasEntity,
    streetName,
    houseNumber,
    additionalAddressLine,
    zipCode,
    city,
    country,
    isReadyToSubmitKyc,
) => ({
        type: UPDATE_AFTER_SAVE_PROFILE,
        role: role,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        title: title,
        dateOfBirth: dateOfBirth,
        gender: gender,
        nationality: nationality,
        hasEntity: hasEntity,
        streetName: streetName,
        houseNumber: houseNumber,
        additionalAddressLine: additionalAddressLine,
        zipCode: zipCode,
        city: city,
        country: country,
        isReadyToSubmitKyc: isReadyToSubmitKyc,
})

export function fetchProfile() {
    return async dispatch => {
        dispatch(requestProfile())

        try {
            const response = await getAuth('/user/profile')
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(receiveProfile(
                    body.data.uuid,
                    body.data.role,
                    body.data.emailAddress,
                    body.data.firstName,
                    body.data.middleName,
                    body.data.lastName,
                    body.data.phoneNumber,
                    body.data.title,
                    body.data.dateOfBirth,
                    body.data.gender,
                    body.data.nationality,
                    body.data.registrationDate,
                    body.data.kycStatus,
                    body.data.hasEntity,
                    body.data.streetName,
                    body.data.houseNumber,
                    body.data.additionalAddressLine,
                    body.data.zipCode,
                    body.data.city,
                    body.data.country,
                    body.data.isReadyToSubmitKyc
                )) 
            } else {
                dispatch(failureProfile(body.message))
            }
        } catch (e) {
            dispatch(failureProfile('Something went wrong'))
        }
    }
}
