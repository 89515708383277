import React from 'react'
import { Switch, Route } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import SignIn from "../../pages/SignIn/"
import Register from "../../pages/Register/"
import SignOut from "../../pages/SignOut"
import Profile from "../../pages/Profile"
import Company from "../../pages/Company"
import Activation from "../../pages/Activation"
import Home from "../../pages/Home"
import CreateProductListing from "../../pages/ProductListing/CreateProductListing"
import SingleProductListing from "../../pages/ProductListing/SingleProductListing"
import MyProductListings from "../../pages/ProductListing/MyProductListings"
import MyProductListing from "../../pages/ProductListing/MyProductListing"
import SearchProductListings from "../../pages/ProductListing/SearchProductListings"

function Routes({ isValidSession }) {
    return (
        <Switch>
            {/* Routes that are only available once signed in (require JWT to display their data) */}
            <PrivateRoute isValidSession={isValidSession} path="/profile" component={Profile} />
            <PrivateRoute isValidSession={isValidSession} path="/company" component={Company} />
            <PrivateRoute isValidSession={isValidSession} path="/products/mine" component={MyProductListings} />
            <PrivateRoute isValidSession={isValidSession} path="/products/search" component={SearchProductListings} />
            <PrivateRoute isValidSession={isValidSession} path="/product/new" component={CreateProductListing} />
            <PrivateRoute isValidSession={isValidSession} path="/product/mine/:uuid" component={MyProductListing} />
            <PrivateRoute isValidSession={isValidSession} path="/product/view/:uuid" component={SingleProductListing} />

            {/* Routes that only make sense when signed in, but don't require JWT */}
            <Route path="/signout" render={(props) => <SignOut {...props} />} />

            {/* Routes that only make sense when signed out */}
            <Route path="/signin" render={(props) => <SignIn {...props} />} />
            <Route path="/register" render={(props) => <Register {...props} />} />
            <Route path="/activate/:uuid/:activationCode" render={(props) => <Activation {...props} />} />

            {/* Routes that are always allowed to access (do not require JWT) */}
            <Route path="/"><Home /></Route>
        </Switch>
    )
}
export default Routes
