import * as actions from '../actions/company'

export const initialState = {
    uuid: '',
    name: '',
    emailAddress: '',
    phoneNumber: '',
    streetName: '',
    houseNumber: '',
    additionalAddressLine: '',
    zipCode: '',
    city: '',
    country: '',
    incorporationNumber: '',
    incorporationType: 'PRIVATE_LIMITED',
    yearOfIncorporation: 2020,
    lastYearFiscalRevenue: 0,
    generalDirector: '',
    website: '',
    exists: false,
    loading: true,
    hasErrors: false,
    errorMessage: '',
    kybStatus: 'open',
}

export default function companyReducer(state = initialState, action) {
    switch (action.type) {
        case actions.COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.COMPANY_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                uuid: '',
                name: '',
                emailAddress: '',
                phoneNumber: '',
                streetName: '',
                houseNumber: '',
                additionalAddressLine: '',
                zipCode: '',
                city: '',
                country: '',
                incorporationNumber: '',
                incorporationType: 'PRIVATE_LIMITED',
                yearOfIncorporation: 2020,
                lastYearFiscalRevenue: 0,
                generalDirector: '',
                website: '',
                kybStatus: 'open',
                exists: false,
                errorMessage: action.errorMessage,
            }
        case actions.COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                uuid: action.payload.uuid,
                name: action.payload.name,
                emailAddress: action.payload.emailAddress,
                phoneNumber: action.payload.phoneNumber,
                streetName: action.payload.streetName,
                houseNumber: action.payload.houseNumber,
                additionalAddressLine: action.payload.additionalAddressLine,
                zipCode: action.payload.zipCode,
                city: action.payload.city,
                country: action.payload.country,
                incorporationNumber: action.payload.incorporationNumber,
                incorporationType: action.payload.incorporationType,
                yearOfIncorporation: action.payload.yearOfIncorporation,
                lastYearFiscalRevenue: action.payload.lastYearFiscalRevenue,
                generalDirector: action.payload.generalDirector,
                website: action.payload.website,
                kybStatus: action.payload.kybStatus,
                exists: action.payload.exists,
                errorMessage: '',
            }
        case actions.UPDATE_AFTER_SAVE_COMPANY:
            return {
                ...state,
                name: action.payload.name,
                emailAddress: action.payload.emailAddress,
                phoneNumber: action.payload.phoneNumber,
                streetName: action.payload.streetName,
                houseNumber: action.payload.houseNumber,
                additionalAddressLine: action.payload.additionalAddressLine,
                zipCode: action.payload.zipCode,
                city: action.payload.city,
                country: action.payload.country,
                incorporationNumber: action.payload.incorporationNumber,
                incorporationType: action.payload.incorporationType,
                yearOfIncorporation: action.payload.yearOfIncorporation,
                lastYearFiscalRevenue: action.payload.lastYearFiscalRevenue,
                generalDirector: action.payload.generalDirector,
                website: action.payload.website,
                exists: true,
            }
        case actions.UPDATE_AFTER_SUBMIT_KYB:
            return {
                ...state,
                kybStatus: action.kybStatus,
            }
        case actions.UPDATE_AFTER_RESET_KYB:
            return {
                ...state,
                kybStatus: action.kybStatus,
            }
        default:
            return state
    }
}