import React from "react"
import { useField } from 'formik'
import { Typography, Slider as MuiSlider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    slider: {
        marginTop: '30px',
    },
    sliderLabel: {
        marginTop: '10px',
    },
})

function Slider({ label, ...props }) {
    const classes = useStyles()
    const [field, , helpers] = useField(props)

    return (
        <React.Fragment>
            <Typography id={field.id} gutterBottom className={classes.sliderLabel}>
                {label}
            </Typography>
            <MuiSlider
                className={classes.slider}
                {...field}
                {...props}
                aria-labelledby={field.id}
                onBlur={(e) => helpers.setTouched(e)}
                onChange={(e, v) => helpers.setValue(v)}
                valueLabelDisplay="on"
            />
        </React.Fragment>
    )
}

export default Slider
