import config from "../config"
import fetch from "cross-fetch"
import { store } from "../store"
import { signOut } from "../actions/signIn"

/**
 * Sends GET request
 * 
 * @param {*} path the last part of the endpoint that immidiately comes after the backend base url as set in the config file
 */
export const get = (path) => {
    return fetch(`${config.backendBaseUrl}${path}`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * Sends POST request
 * 
 * @param {*} path the last part of the endpoint that immidiately comes after the backend base url as set in the config file
 * @param {*} payload the JSON payload (not stringified)
 */
export const post = (path, payload) => {
    return fetch(`${config.backendBaseUrl}${path}`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
}

/**
 * Sends GET request with JWT attached from the store
 * 
 * @param {*} path the last part of the endpoint that immidiately comes after the backend base url as set in the config file
 */
export const getAuth = async (path) => {
    const state = store.getState()

    return fetch(`${config.backendBaseUrl}${path}`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `Bearer ${state.signIn.jwt}`
        },
    }).then(response => {
        if (response.status === 403) {
            store.dispatch(signOut())
            throw new Error('Unauthorized')
        }

        return response
    })
}

/**
 * Sends POST request with JWT attached from the store
 * 
 * @param {*} path the last part of the endpoint that immidiately comes after the backend base url as set in the config file
 * @param {*} payload the JSON payload (not stringified)
 */
export const postAuth = async (path, payload) => {
    const state = store.getState()
    
    return fetch(`${config.backendBaseUrl}${path}`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.signIn.jwt}`
        },
        body: JSON.stringify(payload),
    }).then(response => {
        if (response.status === 403) {
            store.dispatch(signOut())
            throw new Error('Unauthorized')
        }

        return response
    })
}

/**
 * Sends POST request with JWT attached only containing files in FormData obj
 * 
 * @param {*} path the last part of the endpoint that immidiately comes after the backend base url as set in the config file
 * @param {*} payload a FormData object
 */
export const uploadFiles = async (path, payload) => {
    const state = store.getState()

    return fetch(`${config.backendBaseUrl}${path}`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `Bearer ${state.signIn.jwt}`
        },
        body: payload,
    }).then(response => {
        if (response.status === 403) {
            store.dispatch(signOut())
            throw new Error('Unauthorized')
        }

        return response
    })
}
