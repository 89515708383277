import { uploadFiles } from '../services/http'
import { updateAfterSubmitKyb } from './company'

export const SUBMIT_KYB_REQUEST = 'SUBMIT_KYB_REQUEST'
export const SUBMIT_KYB_SUCCESS = 'SUBMIT_KYB_SUCCESS'
export const SUBMIT_KYB_FAILURE = 'SUBMIT_KYB_FAILURE'

export const requestSubmitKyb = () => ({
    type: SUBMIT_KYB_REQUEST,
})

export const successSubmitKyb = () => ({
    type: SUBMIT_KYB_SUCCESS,
})

export const failureSubmitKyb = (errorMessage) => ({
    type: SUBMIT_KYB_FAILURE,
    errorMessage: errorMessage,
})

export function fetchSubmitKyb(files) {
    return async dispatch => {
        dispatch(requestSubmitKyb())

        try {
            const response = await uploadFiles('/company/profile/submitkyb', files)
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(updateAfterSubmitKyb('submitted'))
                dispatch(successSubmitKyb())
            } else {
                dispatch(failureSubmitKyb(body.message))
            }
        } catch (e) {
            dispatch(failureSubmitKyb('Something went wrong'))
        }
    }
}
