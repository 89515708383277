import React from "react"
import ErrorAlert from "../Common/Alerts/ErrorAlert"
import TextField from "../Common/Forms/Inputs/TextField"
import EmailField from "../Common/Forms/Inputs/EmailField"
import CountrySelect from "../Common/Forms/CountrySelect"
import DropDown from '../Common/Forms/DropDown'
import SubmitButton from '../Common/Forms/Buttons/SubmitButton'
import Button from '../Common/Forms/Buttons/Button'
import PaperTitle from '../Common/Typography/PaperTitle'
import PageTitle from "../Common/Typography/PageTitle"
import Paper from "../Common/Paper"
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FaSave, FaCaretRight } from "react-icons/fa"
import { Formik, Form } from "formik"

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    buttons: {
        paddingLeft: '38px',
    },
}))

function CompanyForm({ company, saveCompanyState, saveCompany, changeStep }) {
    const classes = useStyles()
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

    return (
        <React.Fragment>
            <PageTitle>Please complete your company's profile</PageTitle>

            <Formik
                initialValues={{
                    name: company.name,
                    emailAddress: company.emailAddress,
                    phoneNumber: company.phoneNumber,
                    streetName: company.streetName,
                    houseNumber: company.houseNumber,
                    additionalAddressLine: company.additionalAddressLine,
                    zipCode: company.zipCode,
                    city: company.city,
                    country: company.country,
                    incorporationNumber: company.incorporationNumber,
                    incorporationType: company.incorporationType,
                    yearOfIncorporation: company.yearOfIncorporation,
                    lastYearFiscalRevenue: company.lastYearFiscalRevenue,
                    generalDirector: company.generalDirector,
                    website: company.website,
                }}
                validate={values => {
                    let errors = {}

                    if (values.name === '') errors.name = 'Company name is required'
                    if (values.phoneNumber === '') errors.phoneNumber = 'Phone number is required'
                    if (values.streetName === '') errors.streetName = 'Street name is required'
                    if (values.houseNumber === '') errors.houseNumber = 'House number is required'
                    if (values.zipCode === '') errors.zipCode = 'Zip/postal code is required'
                    if (values.city === '') errors.city = 'City is required'
                    if (values.country === '') errors.country = 'Country is required'
                    if (values.incorporationNumber === '') errors.incorporationNumber = 'Incorporation number is required'
                    if (values.generalDirector === '') errors.generalDirector = 'Full name of general director is required'
                    if (values.yearOfIncorporation === '') {
                        errors.yearOfIncorporation = 'Year of incorporation is required'
                    } else if (values.yearOfIncorporation <= 1800) {
                        errors.yearOfIncorporation = 'Please provide a valid year of incorporation'
                    }
                    if (!values.incorporationType || values.incorporationType === '') {
                        errors.incorporationType = 'You have to select an incorporation type'
                    }
                    if (values.emailAddress === '') {
                        errors.emailAddress = 'Email is required'
                    } else if (!emailTest.test(values.emailAddress)) {
                        errors.emailAddress = 'Invalid email address format'
                    }

                    return errors
                }}
                onSubmit={event => saveCompany(event)}
            >
                {({ handleBlur, handleChange, values, errors }) => (
                    <Form>
                        {saveCompanyState.hasErrors &&
                            <ErrorAlert title="Something went wrong while trying to save your company's profile...">
                                {saveCompanyState.errorMessage}
                            </ErrorAlert>
                        }
                        
                        <div className={classes.root}>
                            <Paper>
                                <PaperTitle>Incorporation details</PaperTitle>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField inputName="name" label="Name" value={values.name} required />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            inputName="incorporationNumber"
                                            label="Incorporation Number"
                                            value={values.incorporationNumber}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DropDown
                                            label="Incorporation Type:"
                                            selectedValue={values.incorporationType}
                                            id="incorporationType"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.incorporationType}
                                            required
                                            options={[
                                                { label: 'Sole trader', value: 'SOLE_TRADER' },
                                                { label: 'Private limited', value: 'PRIVATE_LIMITED' },
                                                { label: 'Limited liability partnership', value: 'LIMITED_LIABILITY_PARTNERSHIP' },
                                                { label: 'Public limited', value: 'PUBLIC_LIMITED' },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            inputName="yearOfIncorporation"
                                            label="Year of incorporation"
                                            value={values.yearOfIncorporation}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            inputName="lastYearFiscalRevenue"
                                            label="Last year fiscal revenue"
                                            value={values.lastYearFiscalRevenue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            inputName="generalDirector"
                                            label="General director (full name)"
                                            value={values.generalDirector}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper>
                                <PaperTitle>Contact details</PaperTitle>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <EmailField
                                            inputName="emailAddress"
                                            label="Company email address"
                                            value={values.emailAddress}
                                            required
                                            enableAutoComplete
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField inputName="phoneNumber" label="Company phone number" value={values.phoneNumber} required />
                                    </Grid>
                                    <Grid item xs={8} md={9} lg={10}>
                                        <TextField inputName="streetName" label="Street name" value={values.streetName} required />
                                    </Grid>
                                    <Grid item xs={4} md={3} lg={2}>
                                        <TextField inputName="houseNumber" label="House number" value={values.houseNumber} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            inputName="additionalAddressLine"
                                            label="Additional address line"
                                            value={values.additionalAddressLine}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField inputName="zipCode" label="Zip/postal code" value={values.zipCode} required />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField inputName="city" label="City" value={values.city} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CountrySelect
                                            label="Country"
                                            value={values.country}
                                            name="country"
                                            required
                                            error={errors.country}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField inputName="website" label="Company website" value={values.website} />
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Grid container spacing={2} className={classes.buttons}>
                                <Grid item>
                                    <SubmitButton
                                        disabled={saveCompanyState.loading}
                                        startIcon={<FaSave />}
                                    >
                                        {saveCompanyState.loading ? "Please wait..." : "Save"}
                                    </SubmitButton>
                                </Grid>

                                <Grid item>
                                    <Button
                                        disabled={!company.exists || saveCompanyState.loading}
                                        onClick={() => changeStep(2)}
                                        endIcon={<FaCaretRight />}
                                    >
                                        {saveCompanyState.loading ? "Please wait..." : "Next"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default CompanyForm
