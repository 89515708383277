export const friendlyVerificationStatus = status => {
    switch (status) {
        case 'open':
            return 'Ready to Submit'
        case 'submitted':
            return 'Pending'
        case 'invalid':
            return 'Rejected'
        case 'valid':
            return 'Verified'
        default:
            return 'Unknown'
    }
}
