import React from "react"
import { Backdrop } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Spinner from "../Spinner"

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
}))

function FullPageLoader({ open }) {
    const classes = useStyles()

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <Spinner spinnerType="Watch" />
        </Backdrop>
    )
}

export default FullPageLoader
