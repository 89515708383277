import { getAuth } from '../services/http'

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST'
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS'
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE'

export const requestCategories = () => ({
    type: CATEGORIES_REQUEST,
})

export const receiveCategories = payload => ({
    type: CATEGORIES_SUCCESS,
    payload: payload,
})

export const failureCategories = errorMessage => ({
    type: CATEGORIES_FAILURE,
    errorMessage: errorMessage,
})

export function fetchCategories() {
    return async dispatch => {
        dispatch(requestCategories())

        try {
            const response = await getAuth('/categories')
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(receiveCategories(body.data))
            } else {
                dispatch(failureCategories(body.message))
            }
        } catch (e) {
            dispatch(failureCategories('Something went wrong'))
        }
    }
}
