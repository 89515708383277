import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Button } from "@material-ui/core"
import { FaTrashAlt } from "react-icons/fa"
import { friendlyVerificationStatus } from "../../services/friendlyText"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    h5: {
        margin: '10px 0',
    },
    h6: {
        margin: '5px 0',
        textTransform: 'uppercase',
    },
    p: {
        fontSize: '14px',
        lineHeight: '21px',
    },
    cardContainer: {
        margin: '25px',
        backgroundColor: product => product.listingType === 'offer' ? theme.palette.secondary.light : theme.palette.primary.light,
        borderRadius: '5px',
        boxShadow: '0px 10px 20px -10px rgba(0,0,0,0.75)',
        color: product => product.listingType === 'offer' ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText,
        paddingTop: '30px',
        position: 'relative',
        width: '350px',
        maxWidth: '100%',
        textAlign: 'center',
    },
    round: {
        border: '3px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '50%',
        width: '100px',
        height: '100px',
    },
    status: {
        color: theme.palette.getContrastText(theme.palette.grey.A700),
        backgroundColor: theme.palette.grey.A700,
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '3px 7px',
        position: 'absolute',
        top: '15px',
        left: '15px',
        textTransform: 'uppercase',
    },
    listingType: {
        color: product => product.listingType === 'offer' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
        backgroundColor: product => product.listingType === 'offer' ? theme.palette.primary.main : theme.palette.secondary.main,
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '3px 7px',
        position: 'absolute',
        top: '45px',
        left: '15px',
        textTransform: 'uppercase',
    },
    deleteButton: {
        position: 'absolute',
        top: '15px',
        right: '15px',
    },
    specs: {
        backgroundColor: product => product.listingType === 'offer' ? theme.palette.secondary.main : theme.palette.primary.main,
        textAlign: 'left',
        padding: '12px',
        marginTop: '20px',
    },
    ul: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
    viewButton: {
        height: '100%',
        width: '100%',
    },
    '@global': {
        '.myProductSnippet li': {
            border: '1px solid',
            borderRadius: '4px',
            display: 'inline-block',
            fontSize: '12px',
            margin: '0 7px 7px 0',
            padding: '7px',
        },
        '.myProductSnippet.offer li': {
            borderColor: theme.palette.primary.main,
        },
        '.myProductSnippet.request li': {
            borderColor: theme.palette.secondary.main,
        },
    },
}))

function MyProductSnippet({ product, onDeleteClick }) {
    const classes = useStyles(product)
    const lowestPrice = product.pricings.sort((a, b) => a.price.compare(b.price))[0]

    return (
        <div className={classes.cardContainer}>
            <div className={classes.status}>
                {friendlyVerificationStatus(product.verificationStatus)}
            </div>
            <div className={classes.listingType}>
                {product.listingType}
            </div>
            <div className={classes.deleteButton}>
                <IconButton
                    size="small"
                    color="inherit"
                    onClick={onDeleteClick}
                >
                    <FaTrashAlt />
                </IconButton>
            </div>
            <img className={classes.round} src={product.images[0].thumbnailUrl} alt={product.title} />
            <Typography variant="h5" className={classes.h5}>{product.title}</Typography>
            <Typography variant="h6" className={classes.h6}>{product.brand}</Typography>
            <p className={classes.p}>{product.model}</p>
            <div className={classes.specs}>
                <Typography variant="h6" className={classes.h6}>Specifications</Typography>
                <ul className={`${classes.ul} myProductSnippet ${product.listingType}`}>
                    <li>{product.isShippingOffered ? "Includes shipping" : "No shipping"}</li>
                    <li>MOQ: {product.moq}</li>
                    <li>{product.categoryName}</li>
                    <li>{product.productTypeName}</li>
                    <li>Lowest ppu: $ {lowestPrice.price.toFixed(3)} ({lowestPrice.pricingType})</li>
                </ul>
            </div>
            <div>
                <Button
                    size="large"
                    className={classes.viewButton}
                    variant="contained"
                    color={product.listingType === 'offer' ? 'primary' : 'secondary'}
                    component={Link}
                    to={`/product/mine/${product.uuid}`}
                >
                    view
                </Button>
            </div>
        </div>
    )
}

export default MyProductSnippet
