import React from "react"
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core"
import { Link } from "react-router-dom"

function AuthNavLink({ icon, caption, to }) {
    return (
        <ListItem button component={Link} to={to}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={caption} />
        </ListItem>
    )
}

export default AuthNavLink
