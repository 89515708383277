import { postAuth } from '../services/http'
import { updateAfterSaveCompany } from './company'

export const SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST'
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS'
export const SAVE_COMPANY_FAILURE = 'SAVE_COMPANY_FAILURE'

export const requestSaveCompany = () => ({
    type: SAVE_COMPANY_REQUEST,
})

export const receiveSaveCompany = () => ({
    type: SAVE_COMPANY_SUCCESS,
})

export const failureSaveCompany = errorMessage => ({
    type: SAVE_COMPANY_FAILURE,
    errorMessage: errorMessage,
})

export function fetchSaveCompany(payload) {
    return async dispatch => {
        dispatch(requestSaveCompany())

        try {
            const response = await postAuth('/company/profile', {
                name: payload.name,
                emailAddress: payload.emailAddress,
                phoneNumber: payload.phoneNumber,
                streetName: payload.streetName,
                houseNumber: payload.houseNumber,
                additionalAddressLine: payload.additionalAddressLine,
                zipCode: payload.zipCode,
                city: payload.city,
                country: payload.country,
                incorporationNumber: payload.incorporationNumber,
                incorporationType: payload.incorporationType,
                yearOfIncorporation: payload.yearOfIncorporation,
                lastYearFiscalRevenue: payload.lastYearFiscalRevenue,
                generalDirector: payload.generalDirector,
                website: payload.website,
            })

            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(updateAfterSaveCompany(payload))
                dispatch(receiveSaveCompany())
            } else {
                dispatch(failureSaveCompany(body.message))
            }
        } catch (e) {
            dispatch(failureSaveCompany('Something went wrong'))
        }
    }
}
