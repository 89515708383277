import React from "react"
import Loader from "react-loader-spinner"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
    spinner: {
        margin: '40px',
    },
}))

function Spinner({ spinnerType }) {
    const classes = useStyles()

    return (
        <Loader
            type={spinnerType == null ? "Audio" : spinnerType}
            color="#0a97b0"
            height={200}
            width={200}
            className={classes.spinner}
        />
    )     
}
export default Spinner
