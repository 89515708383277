import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    title: {
        paddingBottom: '5px',
        fontWeight: '800',
        textTransform: 'uppercase',
        boxShadow: 'inset 0 -30px 0px rgba(27,108,168,0.2)',
    },
}))

function PageTitle({ children }) {
    const classes = useStyles()

    return (
        <Typography variant="h4" className={classes.title}>
            {children}
        </Typography>
    )
}

export default PageTitle
