import { postAuth } from '../services/http'

export const CREATE_PRODUCT_LISTING_REQUEST = 'CREATE_PRODUCT_LISTING_REQUEST'
export const CREATE_PRODUCT_LISTING_SUCCESS = 'CREATE_PRODUCT_LISTING_SUCCESS'
export const CREATE_PRODUCT_LISTING_FAILURE = 'CREATE_PRODUCT_LISTING_FAILURE'
export const AFTER_PRODUCT_CREATED_NEXT_STEP = 'AFTER_PRODUCT_CREATED_NEXT_STEP'

export const requestCreateProductListing = () => ({
    type: CREATE_PRODUCT_LISTING_REQUEST,
})

export const receiveCreateProductListing = uuid => ({
    type: CREATE_PRODUCT_LISTING_SUCCESS,
    uuid: uuid,
})

export const failureCreateProductListing = errorMessage => ({
    type: CREATE_PRODUCT_LISTING_FAILURE,
    errorMessage: errorMessage,
})

export const afterProductCreatedNextStep = () => ({
    type: AFTER_PRODUCT_CREATED_NEXT_STEP,
})

export function fetchCreateProductListing(payload) {
    return async dispatch => {
        dispatch(requestCreateProductListing())

        try {
            const response = await postAuth('/product', payload)
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(receiveCreateProductListing(body.message))
            } else {
                dispatch(failureCreateProductListing(body.message))
            }
        } catch (e) {
            dispatch(failureCreateProductListing('Something went wrong'))
        }
    }
}
