import { getAuth } from '../services/http'
import { afterProductCreatedNextStep } from './createProductListing'

export const MY_PRODUCTS_REQUEST = 'MY_PRODUCTS_REQUEST'
export const MY_PRODUCTS_SUCCESS = 'MY_PRODUCTS_SUCCESS'
export const MY_PRODUCTS_FAILURE = 'MY_PRODUCTS_FAILURE'

export const requestMyProducts = () => ({
    type: MY_PRODUCTS_REQUEST,
})

export const receiveMyProducts = payload => ({
    type: MY_PRODUCTS_SUCCESS,
    payload: payload,
})

export const failureMyProducts = errorMessage => ({
    type: MY_PRODUCTS_FAILURE,
    errorMessage: errorMessage,
})

export function fetchMyProducts() {
    return async dispatch => {
        dispatch(requestMyProducts())

        try {
            const response = await getAuth('/products/mine')
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(afterProductCreatedNextStep())
                dispatch(receiveMyProducts(body.data))
            } else {
                dispatch(failureMyProducts(body.message))
            }
        } catch (e) {
            dispatch(failureMyProducts('Something went wrong'))
        }
    }
}
