import * as actions from '../actions/createProductListing'

export const initialState = {
    loading: false,
    hasErrors: false,
    isCreated: false,
    uuid: '',
    errorMessage: '',
}

export default function createProductListingReducer(state = initialState, action) {
    switch (action.type) {
        case actions.CREATE_PRODUCT_LISTING_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                isCreated: false,
                uuid: '',
                errorMessage: '',
            }
        case actions.CREATE_PRODUCT_LISTING_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                isCreated: false,
                uuid: '',
                errorMessage: action.errorMessage,
            }
        case actions.CREATE_PRODUCT_LISTING_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                isCreated: true,
                uuid: action.uuid,
                errorMessage: '',
            }
        case actions.AFTER_PRODUCT_CREATED_NEXT_STEP:
            return {
                ...state,
                uuid: '',
                isCreated: false,
            }
        default:
            return state
    }
}
