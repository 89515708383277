import React from "react"
import CreateProductListingForm from "../../components/ProductListing/CreateProductListingForm"
import Spinner from "../../components/Spinner"
import ErrorAlert from "../../components/Common/Alerts/ErrorAlert"
import { Redirect } from "react-router-dom"

function CreateProductListingView({
    createProductListing,
    createProductListingState,
    profile,
    categories,
    productTypes,
}) {
    return (
        <React.Fragment>
            {
                createProductListingState.isCreated ?
                    <Redirect to={`/product/mine/${createProductListingState.uuid}`} />
                    :
                    (profile.hasErrors || categories.hasErrors || productTypes.hasErrors) ?
                        <ErrorAlert title="Something went wrong while trying to load this page">
                            <ul>
                                {profile.hasErrors && <li>Could not load user profile</li>}
                                {categories.hasErrors && <li>Could not load categories</li>}
                                {productTypes.hasErrors && <li>Could not load product types</li>}
                            </ul>
                        </ErrorAlert>
                        :
                        (profile.loading || categories.loading || productTypes.loading) ?
                            <Spinner />
                            :
                            <CreateProductListingForm
                                createProductListing={createProductListing}
                                createProductListingState={createProductListingState}
                                profile={profile}
                                categories={categories}
                                productTypes={productTypes}
                            />
            }
        </React.Fragment>
    )
}

export default CreateProductListingView
