import React from "react"
import { Grid } from "@material-ui/core"
import Paper from "../Paper"
import InfoAlert from "../Alerts/InfoAlert"
import PaperTitle from '../Typography/PaperTitle'
import { makeStyles } from "@material-ui/core/styles"
import document from "../../../images/document.png"

const useStyles = makeStyles(() => ({
    img: {
        marginBottom: '30px',
    },
    paperTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}))

function VerificationPending({ submission, message }) {
    const classes = useStyles()

    return (
        <Grid container justify="center">
            <Grid item md={2}></Grid>

            <Grid item md={8}>
                <Paper>
                    <Grid container justify="center">
                        <Grid item>
                            <img className={classes.img} width="250" src={document} alt="Documents pending" />
                        </Grid>
                    </Grid>

                    <PaperTitle className={classes.paperTitle}>
                        Your {submission} submission is under review
                    </PaperTitle>
                    
                    <InfoAlert title="Thank you for your submission">
                        {message}
                    </InfoAlert>
                </Paper>
            </Grid>

            <Grid item md={2}></Grid>
        </Grid>
    )
}

export default VerificationPending
