import * as actions from '../actions/resetKyc'

export const initialState = {
    loading: false,
    hasErrors: false,
    errorMessage: '',
}

export default function resetKycReducer(state = initialState, action) {
    switch (action.type) {
        case actions.RESET_KYC_REQUEST:
            return {
                ...state,
                loading: true,
                hasErrors: false,
                errorMessage: '',
            }
        case actions.RESET_KYC_FAILURE:
            return {
                ...state,
                loading: false,
                hasErrors: true,
                errorMessage: action.errorMessage,
            }
        case actions.RESET_KYC_SUCCESS:
            return {
                ...state,
                loading: false,
                hasErrors: false,
                errorMessage: '',
            }
        default:
            return state
    }
}
