import React, { useState } from "react"
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import ReadOnly from "./ReadOnly"
import { BsUpload } from 'react-icons/bs'

const useStyles = makeStyles({
    input: {
        display: 'none',
    },
})

function FileUpload({ caption, inputName, id, onChange, description, accept }) {
    const classes = useStyles()
    const [fileName, setFileName] = useState('')

    const handleChange = e => {
        const file = e.target.files[0]

        if (!(file instanceof File)) return

        setFileName(file.name)

        if (typeof onChange === 'function') onChange(file)
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
                <input
                    accept={accept}
                    className={classes.input}
                    id={id}
                    type="file"
                    name={inputName}
                    onChange={handleChange}
                />
                <label htmlFor={id}>
                    <Button
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<BsUpload/>}
                    >
                        {caption}
                    </Button>
                </label>
            </Grid>
            <Grid item xs>
                <ReadOnly label={description} value={fileName} />
            </Grid>
        </Grid>
    )
}

export default FileUpload
