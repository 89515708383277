import React from "react"
import ErrorAlert from '../Common/Alerts/ErrorAlert'
import AnchorLink from '../Navigation/AnchorLink'
import Paper from "../Common/Paper"
import PaperTitle from "../Common/Typography/PaperTitle"
import { Grid } from "@material-ui/core"
import locked from "../../images/locked.png"
import { makeStyles } from "@material-ui/core/styles"
import config from "../../config"

const useStyles = makeStyles(() => ({
    img: {
        marginBottom: '30px',
    },
    paperTitle: {
        textAlign: 'center',
        marginBottom: '30px',
    },
}))

function ActivationError({ message }) {
    const classes = useStyles()

    return (
        <Grid container justify="center">
            <Grid item md={2}></Grid>

            <Grid item md={8}>
                <Paper>
                    <Grid container justify="center">
                        <Grid item>
                            <img className={classes.img} width="250" src={locked} alt="Activation failed" />
                        </Grid>
                    </Grid>

                    <PaperTitle className={classes.paperTitle}>
                        {message}
                    </PaperTitle>

                    <ErrorAlert title="We're very sorry this happened">
                        Please recheck your email and try clicking the activation button again.
                        If you are still running into problems, do not hesitate to contact us
                        at <AnchorLink href={`mailto:${config.contactEmail}`}>{config.contactEmail}</AnchorLink>.
                    </ErrorAlert>
                </Paper>
            </Grid>

            <Grid item md={2}></Grid>
        </Grid>
    )
}

export default ActivationError
