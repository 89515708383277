import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import DropDown from "../Common/Forms/DropDown"
import CheckBox from "../Common/Forms/CheckBox"
import ProductSnippet from "./ProductSnippet"
import NoResults from "../Common/NoResults"
import Button from "../Common/Forms/Buttons/Button"
import { FaSearch } from "react-icons/fa"

function SearchProductListings({
    filteredProducts,
    fetchFilteredProducts,
    categories,
    productTypes
}) {
    const [category, setCategory] = useState(filteredProducts.category)
    const [productType, setProductType] = useState(filteredProducts.productType)
    const [listingType, setListingType] = useState(filteredProducts.listingType)
    const [isShippingIncluded, setIsShippingIncluded] = useState(filteredProducts.isShippingIncluded)
    const categoryOptions = [{ key: '', label: 'All', value: '' }]
    const productTypeOptions = [{ key: '', label: 'All', value: '', categoryUuid: '' }]
    categories.items.map((category, key) => (
        categoryOptions.push({
            key: key,
            label: category.name,
            value: category.uuid,
        })
    ))
    productTypes.items.map((productType, key) => (
        productTypeOptions.push({
            key: key,
            label: productType.name,
            value: productType.uuid,
            categoryUuid: productType.categoryUuid,
        })
    ))
    const filteredProductTypes = filter => {
        const root = [{ key: '', label: 'All', value: '', categoryUuid: '' }]
        const additional = productTypeOptions.filter(
            productType => (productType.categoryUuid === filter) && (filter !== '')
        )

        return root.concat(additional)
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12} md={6} lg={2}>
                    <DropDown
                        label="Listing type"
                        id="listingType"
                        selectedValue={listingType}
                        onChange={(e, v) => setListingType(v)}
                        options={[
                            { label: 'All', value: '' },
                            { label: 'Requests', value: 'request' },
                            { label: 'Offers', value: 'offer' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <DropDown
                        label="Category"
                        id="category"
                        selectedValue={category}
                        onChange={(e, v) => {
                            setProductType('')
                            setCategory(v)
                        }}
                        options={categoryOptions}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <DropDown
                        label="Subcategory/classification"
                        id="productType"
                        selectedValue={productType}
                        onChange={(e, v) => setProductType(v)}
                        options={filteredProductTypes(category)}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <CheckBox
                        inputName="isShippingIncluded"
                        label="Shipping included"
                        selectedValue={isShippingIncluded}
                        onChange={(e, v) => setIsShippingIncluded(v)}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Button
                        disabled={filteredProducts.loading}
                        startIcon={<FaSearch />}
                        onClick={e => {
                            e.preventDefault()
                            fetchFilteredProducts(
                                isShippingIncluded,
                                category,
                                productType,
                                listingType,
                            )
                        }}
                    >
                        {filteredProducts.loading ? "Please wait..." : "Search"}
                    </Button>
                </Grid>

                <Grid container item>
                    {
                        filteredProducts.items.map((product, key) => (
                            <Grid item key={key} xs={12} md={6} lg={4}>
                                <ProductSnippet product={product} />
                            </Grid>
                        ))
                    }

                    {
                        filteredProducts.items.length === 0 &&
                        <NoResults />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SearchProductListings
