import { postAuth } from '../services/http'
import { updateAfterSaveProfile } from './profile'

export const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST'
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS'
export const SAVE_PROFILE_FAILURE = 'SAVE_PROFILE_FAILURE'

export const requestSaveProfile = () => ({
    type: SAVE_PROFILE_REQUEST,
})

export const receiveSaveProfile = () => ({
    type: SAVE_PROFILE_SUCCESS,
})

export const failureSaveProfile = errorMessage => ({
    type: SAVE_PROFILE_FAILURE,
    errorMessage: errorMessage,
})

export function fetchSaveProfile(
    role,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    title,
    dateOfBirth,
    gender,
    nationality,
    hasEntity,
    streetName,
    houseNumber,
    additionalAddressLine,
    zipCode,
    city,
    country
) {
    return async dispatch => {
        dispatch(requestSaveProfile())

        try {
            const response = await postAuth('/user/profile', {
                role: role,
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                title: title,
                dateOfBirth: dateOfBirth,
                gender: gender,
                nationality: nationality,
                hasEntity: hasEntity,
                streetName: streetName,
                houseNumber: houseNumber,
                additionalAddressLine: additionalAddressLine,
                zipCode: zipCode,
                city: city,
                country: country,
            })

            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(updateAfterSaveProfile(
                    role,
                    firstName,
                    middleName,
                    lastName,
                    phoneNumber,
                    title,
                    dateOfBirth,
                    gender,
                    nationality,
                    hasEntity,
                    streetName,
                    houseNumber,
                    additionalAddressLine,
                    zipCode,
                    city,
                    country,
                    body.message,
                ))
                dispatch(receiveSaveProfile())
            } else {
                dispatch(failureSaveProfile(body.message))
            }
        } catch (e) {
            dispatch(failureSaveProfile('Something went wrong'))
        }
    }
}
