import React from "react"
import { Link } from "react-router-dom"
import {
    List,
    ListItem,
    AppBar,
    Toolbar,
    Button,
    Typography,
    CssBaseline,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ElevationScroll from './ElevationScroll'
import logo from "../../images/logo.png"

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    title: {
        flexGrow: 1,
    },
}))

function PublicDrawer({ ...props }) {
    const classes = useStyles()

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar color="primary">
                    <Toolbar>
                        {/* button needs to remain wrapped up in the Typography
                        component in order for it to be aligned left and the
                        other menu items right
                        
                        TODO: change to logo later
                        */}
                        <Typography variant="h6" className={classes.title}>
                            <Link to="/"><img src={logo} alt="Know Your Trader Logo" /></Link>
                        </Typography>

                        <List component="nav">
                            <ListItem component="div">
                                <Button color="inherit" component={Link} to="/signin">Sign In</Button>
                                <Button color="inherit" component={Link} to="/register">Register</Button>
                            </ListItem> 
                        </List>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            
            {/* this is to add the right amount of space
            between the navbar and the main container */}
            <div className={classes.offset} />
        </React.Fragment>
    )
}

export default PublicDrawer
