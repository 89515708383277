import { postAuth } from '../services/http'
import { fetchMyProducts } from './myProducts'

export const RESET_VERIFICATION_REQUEST = 'RESET_VERIFICATION_REQUEST'
export const RESET_VERIFICATION_SUCCESS = 'RESET_VERIFICATION_SUCCESS'
export const RESET_VERIFICATION_FAILURE = 'RESET_VERIFICATION_FAILURE'

export const requestResetVerification = () => ({
    type: RESET_VERIFICATION_REQUEST,
})

export const successResetVerification = () => ({
    type: RESET_VERIFICATION_SUCCESS,
})

export const failureResetVerification = (errorMessage) => ({
    type: RESET_VERIFICATION_FAILURE,
    errorMessage: errorMessage,
})

export function fetchResetVerification(uuid) {
    return async dispatch => {
        dispatch(requestResetVerification())

        try {
            const response = await postAuth(`/product/resetverification/${uuid}`, {})
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(successResetVerification())
                dispatch(fetchMyProducts())
            } else {
                dispatch(failureResetVerification(body.message))
            }
        } catch (e) {
            dispatch(failureResetVerification('Something went wrong'))
        }
    }
}
