import React from "react"
import SubmitButton from '../Common/Forms/Buttons/SubmitButton'
import EmailField from "../Common/Forms/Inputs/EmailField"
import PasswordField from "../Common/Forms/Inputs/PasswordField"
import { Formik, Form } from "formik"
import ErrorAlert from "../Common/Alerts/ErrorAlert"

function RegisterForm({ registrationState, registerUser }) {
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    const passwordTest = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
                let errors = {}

                if (values.email === '') {
                    errors.email = "Email is required"
                } else if (!emailTest.test(values.email)) {
                    errors.email = "Invalid email address format"
                }

                if (values.password === '') {
                    errors.password = "Password is required"
                } else if (!passwordTest.test(values.password)) {
                    errors.password = `
                        Password (at least 8 characters) must have at least 1 lowercase,
                        1 uppercase and 1 numeric character
                    `
                }

                return errors
            }}
            onSubmit={(event, { resetForm }) => registerUser(event, resetForm)}
        >
            {() => (
                <Form>
                    {registrationState.hasErrors &&
                        <ErrorAlert title="Registration failed">
                            {registrationState.errorMessage}
                        </ErrorAlert>
                    }

                    <div><EmailField inputName="email" label="Email address" enableAutoComplete={false} required /></div>
                    <div><PasswordField inputName="password" label="Password" enableAutoComplete={false} required /></div>
                    <div>
                        <SubmitButton disabled={registrationState.loading}>
                            {registrationState.loading ? "Please wait..." : `Create ${registrationState.role} account`}
                        </SubmitButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default RegisterForm
