import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    button: {
        marginTop: '10px',
    },
})

function SubmitButton({ disabled, children, startIcon, endIcon }) {
    const classes = useStyles()

    return (
        <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={disabled}
            type="submit"
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {children}
        </Button>
    )
}

export default SubmitButton
