import React from "react"
import RegisterView from "../../views/RegisterView/RegisterView"
import ChooseRole from "../../views/RegisterView/ChooseRole"
import { connect } from "react-redux"
import { switchRole, fetchRegistration } from "../../actions/registration"
import { Redirect } from "react-router-dom"

function Register({ fetchRegistration, registrationState, switchRole, signInState }) {
    const registerUser = (values, resetForm) => {
        fetchRegistration(values.email, values.password, registrationState.role)

        if (registrationState.hasErrors) {
            resetForm(values)
        } else {
            resetForm({})
        }
    }

    return (
        <React.Fragment>
            {
                signInState.isValidSession ?
                    <Redirect to="/products/search" />
                    :
                    registrationState.isRegistered ?
                        <Redirect to="/signin" />
                        :
                        registrationState.role === '' ?
                            <ChooseRole switchRole={switchRole} />
                        :
                            <RegisterView
                                registrationState={registrationState}
                                switchRole={switchRole}
                                registerUser={registerUser}
                            />
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    registrationState: state.registration,
    signInState: state.signIn,
})

const mapDispatchToProps = dispatch => {
    return {
        fetchRegistration: (emailAddress, password, role) => dispatch(fetchRegistration(emailAddress, password, role)),
        switchRole: role => dispatch(switchRole(role)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)