import React from "react"
import DropDown from "../Common/Forms/DropDown"
import TextField from "../Common/Forms/Inputs/TextField"
import MultiLine from "../Common/Forms/Inputs/MultiLine"
import { Grid, IconButton } from "@material-ui/core"
import { MdDelete } from "react-icons/md"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    root: {
        borderColor: theme.palette.primary.light,
        borderStyle: 'dashed',
        borderRadius: '5px',
        borderWidth: '1px',
        margin: '8px',
    },
    pad: {
        padding: '12px',
    },
}))

function ProductListingPricingType({ index, pricingType, handleDelete, handleChange, handleBlur }) {
    const classes = useStyles()

    return (
        <Grid item xs={12} className={classes.root}>
            <Grid
                container
                alignItems="flex-end"
                justify="center"
                spacing={4}
                className={classes.pad}
            >
                <Grid item xs={6} md={3}>
                    <DropDown
                        label="Incoterm"
                        id={`pricings.${index}.pricingType`}
                        required
                        options={[
                            { label: 'EXW', value: 'EXW' },
                            { label: 'FCA', value: 'FCA' },
                            { label: 'CPT', value: 'CPT' },
                            { label: 'CIP', value: 'CIP' },
                            { label: 'DPU', value: 'DPU' },
                            { label: 'DAP', value: 'DAP' },
                            { label: 'DDP', value: 'DDP' },
                            { label: 'FAS', value: 'FAS' },
                            { label: 'FOB', value: 'FOB' },
                            { label: 'CFR', value: 'CFR' },
                            { label: 'CIF', value: 'CIF' },
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        selectedValue={pricingType.pricingType}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        inputName={`pricings.${index}.price`}
                        label="Price"
                        value={pricingType.price}
                        required
                    />
                </Grid>
                <Grid item xs={9} md={4}>
                    <MultiLine
                        inputName={`pricings.${index}.description`}
                        label="Description/terms"
                        value={pricingType.description}
                        required
                    />
                </Grid>
                <Grid item xs={3} md={1}>
                    <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={handleDelete}
                    >
                        <MdDelete />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProductListingPricingType
