import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core'

function BiButtonDialog({
    leftBtnCaption,
    rightBtnCaption,
    onClickLeftBtn,
    onClickRightBtn,
    opened,
    title,
    children,
    onClose,
    id,
}) {
    const [open, setOpen] = useState(opened)
    const handleClose = (e, buttonSide) => {
        setOpen(false)

        if (buttonSide === 'l' && onClickLeftBtn != null) onClickLeftBtn(e)
        if (buttonSide === 'r' && onClickRightBtn != null) onClickRightBtn(e)

        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby={`${id}-title`}
            aria-describedby={id}
        >
            <DialogTitle id={`${id}-title`}>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={e => handleClose(e, 'l')} color="primary">
                    {leftBtnCaption}
                </Button>
                <Button onClick={e => handleClose(e, 'r')} color="primary" autoFocus>
                    {rightBtnCaption}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default BiButtonDialog
