import React from "react"
import MUIButton from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    button: {
        marginTop: '10px',
    },
})

function Button({ children, onClick, startIcon, endIcon, disabled, color }) {
    const classes = useStyles()

    return (
        <MUIButton
            className={classes.button}
            variant="contained"
            color={color || 'primary'}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
        >
            {children}
        </MUIButton>
    )
}

export default Button
