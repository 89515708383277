import React from "react"
import VerificationStatus from "../Common/Verification/VerificationStatus"
import SubmitVerification from "./SubmitVerification"
import VerificationPending from "../Common/Verification/VerificationPending"
import VerificationRejected from "../Common/Verification/VerificationRejected"
import VerificationApproved from "../Common/Verification/VerificationApproved"

function MyProductListingOverview({
    product,
    profile,
    submitVerification,
    submitVerificationState,
    resetVerification,
    resetVerificationState,
}) {
    return (
        <React.Fragment>
            <VerificationStatus
                step={2}
                status={product.verificationStatus}
            />

            {
                product.verificationStatus === 'open' &&
                    <SubmitVerification
                        product={product}
                        profile={profile}
                        submitVerification={submitVerification}
                        submitVerificationState={submitVerificationState}
                    />
            }

            {
                product.verificationStatus === 'submitted' &&
                <VerificationPending
                    submission="Product Listing"
                    message="We are reviewing your submission, which will typically take a couple of days to complete. Once it's done, you will receive an email. Please keep an eye on your email or check back later on this page"
                />
            }

            {
                product.verificationStatus === 'invalid' &&
                <VerificationRejected
                    submission="Product Listing"
                    message="Unfortunately, your submission was rejected. Please check the email we sent you for more information on why this happened and how to resubmit your application."
                    reset={resetVerification}
                    resetState={resetVerificationState}
                />
            }

            {
                product.verificationStatus === 'valid' &&
                <VerificationApproved
                    submission="Product Listing"
                    message="Your submission was approved and your product has now been published."
                />
            }
        </React.Fragment>
    )
}

export default MyProductListingOverview
