import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#1b6ca8',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#0a97b0',
            contrastText: '#fff'
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        //theme colors picked from [https://colorhunt.co/palette/184098] in order top to bottom:
        // #1b6ca8
        // #0a97b0
        // #ffd3e1
        // #fce8d5
        background: {
            paper: '#fff',
            default: '#e8f4f8',
        },
    },
})

export default theme
