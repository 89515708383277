import { getAuth } from '../services/http'

export const PRODUCT_TYPES_REQUEST = 'PRODUCT_TYPES_REQUEST'
export const PRODUCT_TYPES_SUCCESS = 'PRODUCT_TYPES_SUCCESS'
export const PRODUCT_TYPES_FAILURE = 'PRODUCT_TYPES_FAILURE'

export const requestProductTypes = () => ({
    type: PRODUCT_TYPES_REQUEST,
})

export const receiveProductTypes = payload => ({
    type: PRODUCT_TYPES_SUCCESS,
    payload: payload,
})

export const failureProductTypes = errorMessage => ({
    type: PRODUCT_TYPES_FAILURE,
    errorMessage: errorMessage,
})

export function fetchProductTypes() {
    return async dispatch => {
        dispatch(requestProductTypes())

        try {
            const response = await getAuth('/producttypes')
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(receiveProductTypes(body.data))
            } else {
                dispatch(failureProductTypes(body.message))
            }
        } catch (e) {
            dispatch(failureProductTypes('Something went wrong'))
        }
    }
}
