import React from "react"
import HomeView from "../../views/HomeView"
import { connect } from "react-redux"
import { Redirect } from "react-router"

function Home({ isValidSession }) {
    return (
        <React.Fragment>
            {
                isValidSession ?
                    <Redirect to="/products/search" />
                    :
                    <HomeView />
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isValidSession: state.signIn.isValidSession,
})

export default connect(mapStateToProps)(Home)
