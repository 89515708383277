import React from "react"
import PublicDrawer from "./PublicDrawer"
import AuthDrawer from "./AuthDrawer"

function Navigation({ isValidSession, profileState, companyState, children }) {
    return (
        <React.Fragment>
            {
                isValidSession ?
                    <AuthDrawer profileState={profileState} companyState={companyState}>
                        {children}
                    </AuthDrawer>
                    :
                    <React.Fragment>
                        <PublicDrawer />
                        {children}
                    </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Navigation
