import { uploadFiles } from '../services/http'
import { updateAfterSubmitKyc } from './profile'

export const SUBMIT_KYC_REQUEST = 'SUBMIT_KYC_REQUEST'
export const SUBMIT_KYC_SUCCESS = 'SUBMIT_KYC_SUCCESS'
export const SUBMIT_KYC_FAILURE = 'SUBMIT_KYC_FAILURE'

export const requestSubmitKyc = () => ({
    type: SUBMIT_KYC_REQUEST,
})

export const successSubmitKyc = () => ({
    type: SUBMIT_KYC_SUCCESS,
})

export const failureSubmitKyc = (errorMessage) => ({
    type: SUBMIT_KYC_FAILURE,
    errorMessage: errorMessage,
})

export function fetchSubmitKyc(files) {
    return async dispatch => {
        dispatch(requestSubmitKyc())

        try {
            const response = await uploadFiles('/user/profile/submitkyc', files)
            const body = await JSON.parse(await response.json())

            if (body.success) {
                dispatch(updateAfterSubmitKyc('submitted', false))
                dispatch(successSubmitKyc())
            } else {
                dispatch(failureSubmitKyc(body.message))
            }
        } catch (e) {
            dispatch(failureSubmitKyc('Something went wrong'))
        }
    }
}
