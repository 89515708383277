import React from "react"
import { Field } from "formik"
import { TextField as FIKTextField } from 'formik-material-ui'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    textField: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '4px',
    },
})

function TextField({ label, inputName, value, required }) {
    const classes = useStyles()
    
    return (
        <Field
            className={classes.textField}
            component={FIKTextField}
            type="text"
            name={inputName}
            label={label}
            value={value}
            required={required}
        />
    )
}

export default TextField
