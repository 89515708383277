import React from 'react'
import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    Toolbar,
    Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
    FaUserAlt,
    FaBuilding,
    FaSignOutAlt,
    FaShoppingCart,
} from "react-icons/fa"
import {
    MdAddCircle,
    MdViewList,
} from "react-icons/md"
import { TiThMenu } from "react-icons/ti"
import logo from "../../images/logo.png"
import { Link } from "react-router-dom"
import AuthNavLink from "./AuthNavLink"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
    },
}))

function AuthDrawer({ window, children, profileState, companyState }) {
    const classes = useStyles()
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

    const isCompletelyVerified = (
        profileState.kycStatus === 'valid' &&
        (
            (profileState.hasEntity && companyState.kybStatus === 'valid') ||
            !profileState.hasEntity
        )
    )

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />

            <List>
                <AuthNavLink to="/profile" icon={<FaUserAlt />} caption="My Profile" />
                {
                    profileState.hasEntity && profileState.kycStatus === 'valid' &&
                    <AuthNavLink to="/company" icon={<FaBuilding />} caption="My Company" />
                }
            </List>

            {
                isCompletelyVerified &&
                <React.Fragment>
                    <Divider />

                    <List>
                        <AuthNavLink to="/products/mine" icon={<MdViewList />} caption="My Product Listings" />
                        <AuthNavLink to="/product/new" icon={<MdAddCircle />} caption="Create Product Listing" />
                    </List>
                </React.Fragment>
            }

            <Divider />

            <List>
                <AuthNavLink to="/products/search" icon={<FaShoppingCart />} caption="Marketplace" />
            </List>

            <Divider />

            <List>
                <AuthNavLink to="/signout" icon={<FaSignOutAlt />} caption="Sign Out" />
            </List>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <div className={classes.root}>
            <CssBaseline />

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <TiThMenu />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        <Link to="/"><img src={logo} alt="Know Your Trader Logo" /></Link>
                    </Typography>
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    )
}

export default AuthDrawer
