import React from "react"
import Typography from '@material-ui/core/Typography'

function HomeView() {
    return (
        <Typography variant="h1" gutterBottom>
            Welcome at <strong>Know Your Trader!</strong>
        </Typography>
    )
}

export default HomeView
